import React from "react";
import {
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  Box,
  Spacer,
  Text,
  Spinner,
  Button,
  useDisclosure,
  HStack,
  Tag,
  Center,
} from "@chakra-ui/react";
import {
  BootstrapTable,
  TableHeaderColumn,
  InsertModalHeader,
} from "react-bootstrap-table";
import { EditIcon, DeleteIcon } from "@chakra-ui/icons";
import { useDispatch, useSelector } from "react-redux";

import { Company, Folder, Password, Role, User } from "../../models/models";
import DynamicModal from "./modals/DynamicModal";
import { getFromStorage, unCryptData } from "../../utils/utils";
import { updateCompanyAdmin } from "../../store/actions";
import EditModal from "./modals/EditModal";
import { variables } from "../../utils/variables";

export default function CompanyTab({
  user,
  users,
  company,
  folders,
  roles,
  passwords,
}: {
  user: User | null;
  users: User[] | null;
  company: Company[] | null;
  folders: Folder[] | null;
  roles: Role[] | null;
  passwords: Password[] | null;
}) {
  const dispatch = useDispatch();

  const displayCompany = (id: string) => {
    if (company) {
      var name = company.find((obj) => obj._id === id);
      if (name) {
        return unCryptData(name.name);
      } else {
        return "";
      }
    } else {
      return "";
    }
  };

  const displayPassword = (password: string) => {
    return new Array(Math.floor(password.length / 8)).join("*");
  };

  const displayFolder = (id: string) => {
    var folder = folders?.find((obj) => obj._id === id);
    if (folder) {
      return (
        <HStack spacing={4}>
          return (
          <Tag
            size={"lg"}
            key={folder?._id}
            variant="subtle"
            colorScheme="teal"
            fontSize={"13px"}
            padding={"5px"}
          >
            {unCryptData(folder?.name)}
          </Tag>
          );
        </HStack>
      );
    } else {
      return <></>;
    }
  };

  const displayString = (string: string) => {
    return <Text>{unCryptData(string)}</Text>;
  };

  const displayAction = (cellContent: any, row: any) => {
    // return (
    //   <Button
    //     colorScheme="red"
    //     onClick={async () => {
    //       var token = localStorage.getItem("token");
    //       if (token) {
    //         var proceed = window.confirm(
    //           "Are you sure you want to remove this item ? \n" +
    //             "This action will delete all password linked to it\n" +
    //             unCryptData(row.name)
    //         );
    //         if (proceed) {
    //           dispatch(removeCompanyAdmin(token, row._id, row.company));
    //         }
    //       }
    //     }}
    //   >
    //     <DeleteIcon />
    //   </Button>
    // );
    return <></>;
  };

  function onAfterSaveCell(row: any, cellName: any, cellValue: any) {
    console.log(row)

    dispatch(updateCompanyAdmin(getFromStorage("token"), row))
  }

  const createCustomModal = (
    onModalClose: any,
    onSave: any,
    columns: any,
    validateState: any,
    ignoreEditable: any
  ) => {
    columns.forEach((column: any) => {
      if (column.value) {
        delete column.value;
      }
    });
    const attr = {
      onModalClose,
      onSave,
      columns,
      validateState,
      ignoreEditable,
      folders,
      roles,
      company: company,
      object: "Folder",
      user: user
    };
    return <DynamicModal {...attr} />;
  };

  const modalEditor = (onUpdate: any, props: any) => (<EditModal onUpdate={ onUpdate } {...props} options={{roles, folders}} crypted={true} object={"company"} />);
  
  const options = {
    insertModal: createCustomModal,
  };

  if (!user || !company) {
    return (
      <Center>
        <Spinner mt={"7vh"} size={"lg"} />
      </Center>
    );
  }

  return (
    <div>
      <BootstrapTable
        data={company}
        insertRow={false}
        //deleteRow={true}
        // search={true}
        cellEdit={{
          mode: "dbclick",
          blurToSave: true,
          afterSaveCell: onAfterSaveCell,
        }}
        //exportCSV={ true }
        options={options}
        striped={variables.striped}
        hover
      >
        <TableHeaderColumn
          isKey
          dataField="_id"
          headerAlign="left"
          dataAlign="left"
          width={"65px"}
          hiddenOnInsert
        >
          ID
        </TableHeaderColumn>
        <TableHeaderColumn
          dataField="name"
          headerAlign="left"
          dataAlign="left"
          dataSort
          dataFormat={displayString}
          customEditor={ { getElement: modalEditor } }
        >
          Name
        </TableHeaderColumn>
        {/* <TableHeaderColumn
          dataField="action"
          export={false}
          editable={false}
          width={"60px"}
          hiddenOnInsert={true}
          dataFormat={displayAction}
          dataAlign="center"
        >
          Action
        </TableHeaderColumn> */}
      </BootstrapTable>
    </div>
  );
}
