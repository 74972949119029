import theme from "./theme";


export const select_style = {
    control: (baseStyles: any, state: any) => ({
        ...baseStyles,
        backgroundColor: theme.styles.bg,
        color: theme.styles.color,
        borderColor: theme.styles.color
    }),
    singleValue:(provided:any) => ({
      ...provided,
      color: theme.styles.color,
    }),
    // option: (styles: any, { data, isDisabled, isFocused, isSelected }: any) => {
    //   return {
    //     ...styles,
    //     backgroundColor: isDisabled
    //       ? undefined
    //       : isSelected
    //       ? theme.styles.bg
    //       : isFocused
    //       ? "#F1F1F1"
    //       : undefined,
    //     color: isDisabled
    //       ? '#ccc'
    //       : isSelected
    //       ? theme.styles.color
    //         ? 'white'
    //         : 'black'
    //       : theme.styles.color,
    //     cursor: isDisabled ? 'not-allowed' : 'default',
  
    //     ':active': {
    //       ...styles[':active'],
    //       backgroundColor: !isDisabled
    //         ? isSelected
    //           ? theme.styles.color
    //           : theme.styles.bg
    //         : undefined,
    //     },
    // }}
}