import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Center,
  Spinner,
  Button,
  HStack,
  Tag,
  Input,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Text,
  useToast,
  Progress,
} from "@chakra-ui/react";

export default function ModalRemove({ onClose, isOpen, action, title, text }: any) {

    return (
        <Modal isOpen={isOpen} onClose={onClose} size={"5xl"}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader fontSize={"18px"}>
                    {title}
                </ModalHeader>
                <ModalCloseButton size={"lg"} m={"10px"} />
                <ModalBody p={"20px 20px"}>
                    <Text fontSize={"16px"}>{text}</Text>
                </ModalBody>
                <ModalFooter>
                <Button
                    mr={3}
                    onClick={onClose}
                    fontSize={"16px"}
                    size={"lg"}
                    variant="ghost"
                >
                    Close
                </Button>
                <Button
                    colorScheme="red"
                    fontSize={"16px"}
                    size={"lg"}
                    onClick={action}
                >
                    Remove
                </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}