import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Center,
  Spinner,
  Button,
  HStack,
  Tag,
  Input,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Text,
  useToast,
} from "@chakra-ui/react";
import secureLocalStorage from "react-secure-storage";
import { CirclePicker, SwatchesPicker } from 'react-color';
import { toast } from 'react-toastify';

import { cryptData, getFromStorage, unCryptData } from "../../../utils/utils";
import { addFolder, addFolderPerso } from "../../../store/actions";
import { Folder } from "../../../models/models";
import { Colors } from "../../../style/color";

export default function ModalAddFolder({ onClose, isOpen, user, addFolder, text }: any) {
    const [name, setName] = React.useState<string>("");   
    const [color, setColor] = React.useState<string>("#3B556D");

    useEffect(() => {
        
        setColor("#3B556D")
        
    }, [isOpen])
  
    function submit() {
        
        if (!name) {
            toast.error("All field are required", {
                position: "top-right",
                autoClose: 8000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
            });
            return;
        }

        var folderObject: Folder = {
            name: cryptData(name),
            user: undefined,
            _id: undefined,
            company: undefined,
            color: cryptData(color),
            created_at: undefined,
            created_by: user._id
        };
        
        setName("");
        onClose();
        addFolder(folderObject);
    }

    return (
        <Modal isOpen={isOpen} onClose={onClose} size={"5xl"}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader fontSize={"18px"}>
                    {text}
                </ModalHeader>
                <ModalCloseButton size={"lg"} m={"10px"} />
                <ModalBody p={"20px 20px"}>
                    <Text fontSize={"16px"}>Name</Text>
                    <Input
                        placeholder="Enter name ..."
                        size="lg"
                        type="text"
                        fontSize={"14px"}
                        value={name}
                        autoFocus={true}
                        onKeyDown={(e) => e.key === 'Enter' && submit()}
                        onChange={(evt) => setName(evt.currentTarget.value)}
                    />
                    <Text fontSize={"16px"} mt={"20px"}>Color</Text>
                    <CirclePicker
                        width="100%"
                        // colors={["#55efc4", "#81ecec", "#74b9ff", "#a29bfe", "#dfe6e9", "#00b894", "#00cec9", "#0984e3", "#6c5ce7", "#b2bec3", "#ffeaa7", "#fab1a0", "#ff7675", "#fd79a8", "#636e72", "#fdcb6e", "#e17055", "#d63031", "#e84393", "#2d3436"]}
                        colors={["#f44336", "#e91e63", "#9c27b0", "#673ab7", "#3f51b5", "#2196f3", "#03a9f4", "#00bcd4", "#009688", "#4caf50", "#8bc34a", "#cddc39", "#ffeb3b", "#ffc107", "#ff9800", "#ff5722", "#795548", "#607d8b", "#3B556D"]} 
                        color={color} 
                        onChangeComplete={(color: any) => {
                            setColor(color.hex);
                        }} />
                </ModalBody>
                <ModalFooter>
                <Button
                    mr={3}
                    onClick={onClose}
                    fontSize={"16px"}
                    size={"lg"}
                    variant="ghost"
                >
                    Close
                </Button>
                <Button
                    colorScheme="blue"
                    fontSize={"16px"}
                    size={"lg"}
                    onClick={() => submit()}
                >
                    Add
                </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}