import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Spinner,
  Box,
  Text,
  Stack,
  Flex,
  Spacer,
  Container,
  Divider,
  Center,
  Link,
  Button,
  useDisclosure,
  useColorModeValue,
  Skeleton, 
  SkeletonCircle, 
  SkeletonText,
  Tooltip,
  Icon
} from "@chakra-ui/react";
import {
  ViewIcon,
  CopyIcon,
  LinkIcon,
  ExternalLinkIcon,
  AddIcon,
  CloseIcon,
} from "@chakra-ui/icons";
import { Button as Button2 } from "react-bootstrap";
import { MdFolder, MdFolderOpen, MdModeEditOutline} from "react-icons/md";

import { Access, Folder, Password, Store, User } from "../models/models";
import { useEffect } from "react";
import { cryptData, getFromStorage, getIcon, subString, unCryptData } from "../utils/utils";
import { ModalAddPassword } from "./admin/modals/ModalAddPassword";

import "../style/fonts.css";
import { removeFolderPerso, removePassword as removePasswordStore, setFolder, setPage, updateFolderPerso } from "../store/actions";
import ModalRemove from "./admin/modals/ModalRemove";
import { Colors } from "../style/color";
import { ManageFolderModal } from "./ManageFolderModal";
import { toast } from "react-toastify";

export default function PasswordView() {
  const passwords: Password[] = useSelector((state: Store) => state.passwords);
  const user: User | undefined = useSelector((state: Store) => state.user);
  const folder: Folder | undefined = useSelector(
    (state: Store) => state.folder
  );

  const [passwordShow, setPasswordShow] = React.useState<Array<boolean>>([]);
  const [passwordSelected, setPasswordSelected] = React.useState<Password|undefined>(undefined);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isOpen: isOpenManageFolder, onOpen: onOpenManageFolder, onClose: onCloseManageFolder } = useDisclosure();
  const { isOpen: isOpenDeletePassword, onOpen: onOpenDeletePassword, onClose: onCloseDeletePassword } = useDisclosure();

  const dispatch = useDispatch();
  const bgPassword = useColorModeValue(Colors.bgWhite, Colors.bgDark);
  const bgDeletBtn = useColorModeValue(Colors.bgGray, Colors.bgDarkMain);
  const color = useColorModeValue(Colors.textWhite, Colors.textGray);

  useEffect(() => {
    var newArray: any = [];

    passwords.forEach((password: Password, index: number) => {
      newArray[index] = false;
    });

    setPasswordShow(newArray);
  }, [passwords]);

  
  function copy(password: string, text: string) {
    navigator.clipboard.writeText(password);
    
    toast.info(text, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      });
  }

  function addPassword(message: string) {
    toast.success(message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      });
  }

  function removePassword() {
    if (passwordSelected) {
      dispatch(removePasswordStore(getFromStorage("token"), passwordSelected?._id, folder?._id));
      onCloseDeletePassword();
      toast.success(unCryptData(passwordSelected.name) + " password removed", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
        });
    }
  }

  function getLink(link: string): string {
    return link.startsWith("http://") || link.startsWith("https://") ? link : "//" + link;
  }

  function updateFolder(folderObj: Folder, name: string, color: string) {
    folderObj.name = cryptData(name);
    folderObj.color = cryptData(color);

    dispatch(updateFolderPerso(getFromStorage("token"), folderObj, user));
    dispatch(setFolder(folderObj));
    onCloseManageFolder();
  }

  const displayPassword = (password: string, show: boolean) => {
    if (show) {
      return password.length > 25 ? password.substring(0, 25).substring(0, 25) + "..." : password;
    } else {
      return "*******";
    }
  };
  
  const deleteButton = () => {
    if (folder?.user === user?._id) {
      return (
//        <Button color={"red.500"} bg={bgDeletBtn} position="absolute" top="10px" right="10px" px="2%" size="lg" fontSize="16px" zIndex={100} onClick={onOpenDeleteFolder}>Delete folder</Button>
        <Box>
          <Button 
            bg={bgDeletBtn} 
            position="absolute" 
            top="10px" 
            right="10px" 
            px="2%" 
            size="lg" 
            fontSize="16px" 
            zIndex={100} 
            onClick={onOpenManageFolder}
            >
            <Icon as={MdModeEditOutline} color={color} mr={"5px"} />
            Modify
          </Button>
        </Box>
      )
    }
  }

  const addButton = () => {
    if (user?.access && ([Access.ADMIN.toString(), Access.USER.toString(), Access.OWNER.toString()].includes(user?.access) || folder?.user === user?._id)) {
      return (
          <Button
            size="lg"
            h={"36px"}
            m="20px"
            fontSize={"14px"}
            bg={Colors.bgCanard}
            color={color}
            onClick={onOpen}
          >
            <AddIcon mr={"10px"} /> Add Password
          </Button>
        )
    }
  }

  const action = (password: Password) => {
    if (folder?.user === user?._id || (user?.access && [Access.OWNER.toString(), Access.ADMIN.toString(), Access.USER].includes(user?.access))) {
      return (
        <Center flex={"0.2"} style={{ borderBottomRightRadius: "10px", borderTopRightRadius: "10px", cursor: "pointer" }} onClick={() => {
            setPasswordSelected(password);
            onOpenDeletePassword();
          }} >
          <CloseIcon m="auto" w={"10px"} h={"10px"} />
        </Center>
      )
    }
  }

  if (!folder || (passwords?.length > 0 && (folder?._id !== passwords[0]?.folder))) {
    return (
      <Center w={"100%"} h={"100%"}>
        <Spinner size={"xl"} />
      </Center>
    )
    return (
      <Box w={"100%"} h={"100vh"}>
        <Box w="100%" p="7% 10% 3% 9%" position={"relative"}>
          <Skeleton height='60px' w='40%' />
        </Box>   
        <Skeleton w={"17%"} h={"40px"} m={"20px"} />
        <Flex h="5vh" w="100%" px="40px" mt={"30px"}>
          <Box flex="1">
            <Skeleton height='20px' w={"35%"} />
          </Box>
          <Box flex="1.5">
            <Skeleton height='20px' w={"45%"} />
          </Box>
          <Box flex="1.6">
            <Skeleton height='20px' w={"45%"} />
          </Box>
          <Box flex="1.9">
            <Skeleton height='20px' w={"30%"} />
          </Box>
        </Flex>
        <Stack mx={"3%"}>
          <Skeleton height='40px' />
          <Skeleton height='40px' />
          <Skeleton height='40px' />
        </Stack>
      </Box>
    )
  }

  return (
    <Box h="100%" w="100%">
      {deleteButton()}
      <Box h="25vh" w="100%" p="5% 2%" position={"relative"}>
        <Text fontSize="80px" top={"70px"} left={65} pl={10} className="titleFolder" position={"absolute"} zIndex={100}>
          {unCryptData(folder?.name)}
        </Text>
        <Box w={200} h={200} top={0} left={15} position={"absolute"} zIndex={0}>
          <svg xmlns="http://www.w3.org/2000/svg" width="150px" height="150px" viewBox="0 0 48 48"><path fill={unCryptData(folder?.color)+"99"} d="M38,12H22l-4-4H8c-2.2,0-4,1.8-4,4v24c0,2.2,1.8,4,4,4h31c1.7,0,3-1.3,3-3V16C42,13.8,40.2,12,38,12z"/><path fill={unCryptData(folder?.color)} d="M42.2,18H15.3c-1.9,0-3.6,1.4-3.9,3.3L8,40h31.7c1.9,0,3.6-1.4,3.9-3.3l2.5-14C46.6,20.3,44.7,18,42.2,18z"/></svg>
        </Box>
      </Box>
      {addButton()}
      <Flex h="5vh" w="100%" px="30px" py={[10, 1]}>
        <Box flex="1">
          <Text>Name</Text>
        </Box>
        <Box flex="1.5">
          <Text>Username</Text>
        </Box>
        <Box flex="1.6">
          <Text>Password</Text>
        </Box>
        <Box flex="1.9">
          <Text>Link</Text>
        </Box>
      </Flex>
      <Divider my={[1, 0]} ml="1.5%" w="98.5%" />
      <Box
        w={"100%"}
        h={"59.9vh"}
        style={{ overflowY: "auto" }}
        className="scrollPerso"
      >
        <Stack direction={["column"]} spacing="12px" w="100%" px="2%" py="2vh">
          {passwords.map((password: Password, index: number) => {
            return (
              <Flex
                key={password._id}
                style={{ borderRadius: "10px" }}
                bg={bgPassword}
                w="100%"
                h="60px"
                pl="20px"
              >
                <Box flex="1.1" my="auto" overflow="hidden">
                  {unCryptData(password.name)}
                </Box>
                <Center flex="0.2" overflow="hidden">
                  <CopyIcon
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      copy(unCryptData(password.username), "Username copied to clipboard")
                    }
                  />
                </Center>
                <Box flex="1.6" my="auto" overflow="hidden">
                  {subString(unCryptData(password.username))}
                </Box>
                <Flex flex="0.3" m="auto" justify="space-around" overflow="hidden">
                  <CopyIcon
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      copy(unCryptData(password.password), "Password copied to clipboard")
                    }
                  />
                  <ViewIcon
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      var newArray = [...passwordShow];
                      if (newArray[index]) {
                        newArray[index] = false;
                      } else {
                        newArray[index] = true;
                      }
                      setPasswordShow(newArray);
                    }}
                  />
                </Flex>
                <Flex flex="1.5" my="auto" overflow="hidden" px="5px">
                  {displayPassword(
                    unCryptData(password.password),
                    passwordShow[index]
                  )}
                </Flex>
                <Center flex="0.2" overflow="hidden">
                  <LinkIcon
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      copy(unCryptData(password.url), "Url copied to clipboard")
                    }
                  />
                </Center>
                <Box flex="2" my="auto" overflow="hidden">
                  <Link href={getLink(unCryptData(password.url))} isExternal>
                    {unCryptData(password.url)} <ExternalLinkIcon mx="2px" />
                  </Link>
                </Box>
                {action(password)}
              </Flex>
            );
          }, [])}
        </Stack>
      </Box>
      <ManageFolderModal onClose={onCloseManageFolder} isOpen={isOpenManageFolder} onOpen={onOpenManageFolder} folder={folder} action={updateFolder} user={user} />
      <ModalAddPassword onClose={onClose} isOpen={isOpen} folder={folder} callback={addPassword} />
      <ModalRemove onClose={onCloseDeletePassword} isOpen={isOpenDeletePassword} action={removePassword} title={"Do you want to remove the password ?"} text={"If the password is deleted, it will not be recoverable"} />
    </Box>
  );
}
