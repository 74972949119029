import axios from "axios";
import React from "react";
import { Password, User } from "../models/models";
import store from "../store/store";

var url = "https://api.passap.fr";

if (process.env.NODE_ENV === 'production') {
  url = "https://api.passap.fr";
}

export function contactEmail(email: string, name: string, subject: string, message: string) {
  return axios.get(url + "/contact/email", {
    params: { email, name, subject, message },
    headers: { Accept: "application/json" },
  });
}

export function loginAPI({
  name,
  password,
  hash,
}: {
  name: string;
  password: string;
  hash: string;
}): Promise<any> {
  return axios.get(url + "/user/login", {
    params: { name, password, hash },
  });
}

export function registerAPI({
  name,
  password,
  email,
  companyObject,
  token
}: {
  name: string;
  password: string;
  email: string;
  companyObject: any;
  token: string;
}): Promise<any> {
  return axios.post(url + "/user/signup", { name, password, email, companyObject, token });
}

export function verifyEmail(token: string, user: User) {
  return axios.get(url + "/user/verifyemail", {
    params: { user },
    headers: { authorization: "Bearer " + token, Accept: "application/json" },
  });
}

export function verifyAccount(userId: string) {
  return axios.get(url + "/user/verifyaccount", {
    params: { userId }
  });
}

export function resetPassword(token: string, name: string, email: string) {
  return axios.get(url + "/user/resetpassword", {
    params: { name, token, email }
  });
}

export function changePassword(token: string, user: User, oldPassword: string, newPassword: string) {
  return axios.put(url + "/user/changepassword", { user, oldPassword, newPassword} , { headers: { authorization: "Bearer " + token, Accept: "application/json" }, });
}

export function checkAdmin(token: string) {
  return axios.get(url + "/user/checkadmin", { headers: { authorization: "Bearer " + token, Accept: "application/json" }, });
}

export function removeAccount(token: string, user: User) {
  return axios.delete(url + "/user/delete", { headers: { authorization: "Bearer " + token, Accept: "application/json" }, params: {user} });
}

export function getFolderAPI(
  token: string,
  folders: string | String[] | null,
  company: string | null | undefined,
  user: string | null | undefined,
): Promise<any> {
  if (company) {
    return axios.get(url + "/folder/get", {
      params: { company: company },
      headers: { authorization: "Bearer " + token, Accept: "application/json" },
    });
  } else if (user) {
    return axios.get(url + "/folder/get", {
      params: { user: user },
      headers: { authorization: "Bearer " + token, Accept: "application/json" },
    });
  } else {
    return axios.get(url + "/folder/get", {
      params: { folder: folders },
      headers: { authorization: "Bearer " + token, Accept: "application/json" },
    });
  }
}

export function getUserAPI(
  token: string,
  user?: String | String[],
  company?: string
): Promise<any> {
  return axios.get(url + "/user/get", {
    params: { user: user, company: company },
    headers: { authorization: "Bearer " + token, Accept: "application/json" },
  });
}

export function getPasswordAPI(
  token: string,
  folder: String | String[] | undefined,
  company: string | null | undefined
): Promise<any> {
  return axios.get(url + "/password/get", {
    params: { folder: folder, company: company },
    headers: { authorization: "Bearer " + token, Accept: "application/json" },
  });
}

export function getCompanyAPI(token: string, company?: string): Promise<any> {
  return axios.get(url + "/company/get", {
    params: { company: company },
    headers: { authorization: "Bearer " + token, Accept: "application/json" },
  });
}

export function getRoleAPI(
  token: string,
  role: string | string[] | null,
  company: string | null
): Promise<any> {
  return axios.get(url + "/role/get", {
    params: { role: role, company: company },
    headers: { authorization: "Bearer " + token, Accept: "application/json" },
  });
}

export function getAloneAPI(token: string): Promise<any> {
  return axios.get(url + "/user/getalone", {
    headers: { authorization: "Bearer " + token, Accept: "application/json" },
  });
}

export function addObjectAPI(
  token: string,
  object: string,
  body: any
): Promise<any> {
  return axios.post(url + "/" + object + "/create", body, {
    headers: { authorization: "Bearer " + token, Accept: "application/json" },
  });
}

export function getObjectAPI(
  token: string,
  object: string,
  params: any
): Promise<any> {
  return axios.get(url + "/" + object + "/get", {
    params: params,
    headers: { authorization: "Bearer " + token, Accept: "application/json" },
  });
}

export function updateObjectAPI(
  token: string,
  object: string,
  body: any
): Promise<any> {
  return axios.put(url + "/" + object + "/update", body, {
    headers: { authorization: "Bearer " + token, Accept: "application/json" },
  });
}

export function removeObjectAPI(
  token: string,
  object: String,
  params: any
): Promise<any> {
  return axios.delete(url + "/" + object + "/delete", {
    params,
    headers: { authorization: "Bearer " + token, Accept: "application/json" },
  });
}
