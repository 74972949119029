import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Center,
  Spinner,
  Button,
  HStack,
  Tag,
  Box,
  Flex,
  Input,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverFooter,
  PopoverArrow,
  PopoverCloseButton,
  PopoverAnchor,
  Icon,
  Text,
} from "@chakra-ui/react";
import { MdVpnKey } from "react-icons/md";
import { toast } from "react-toastify";

import { addPassword as addPasswordStore } from "../../../store/actions";
import { cryptData, getFromStorage, unCryptData } from "../../../utils/utils";
import { Password, Store, User } from "../../../models/models";
import GenerationPanel from "../../GenerationPanel";

export function ModalAddPassword({ onClose, isOpen, folder, callback }: any) {
  const [name, setName] = React.useState<string>("");
  const [username, setUserame] = React.useState<string>("");
  const [password, setPassword] = React.useState<string>("");
  const [link, setLink] = React.useState<string>("");
  const [loading, setLoading] = React.useState<boolean>(false);
  
  const user: User | undefined = useSelector((state: Store) => state.user);

  const dispatch = useDispatch();

  useEffect(() => {
    setPassword("")
  }, [isOpen])

  function addPassword() {
    if (loading) {
      return;
    } else {
      setLoading(true)
    }
    if (!name || !username || !password || !link) {
      toast.error("All field are required", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
        });
      setLoading(false);
      return;
    }
    var passwordObject: Password = {
      _id: undefined,
      name: cryptData(name),
      username: cryptData(username),
      password: cryptData(password),
      url: cryptData(link),
      folder: folder._id,
      created_at: undefined,
      created_by: user?._id
    };
    dispatch(addPasswordStore(getFromStorage("token"), passwordObject, folder._id));
    setName("");
    setUserame("");
    setPassword("");
    setLink("");
    setLoading(false);
    onClose();
    callback("Password " + unCryptData(passwordObject.name) + " Added");
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} size={"5xl"}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader fontSize={"18px"}>
          Add password to {unCryptData(folder.name)} folder
        </ModalHeader>
        <ModalCloseButton size={"lg"} m={"10px"} />
        <ModalBody p={"20px 20px"}>
          <Text fontSize={"16px"}>Name</Text>
          <Input
            placeholder="Enter name ..."
            size="lg"
            type="text"
            fontSize={"14px"}
            value={name}
            autoFocus={true}
            onKeyDown={(e) => e.key === 'Enter' && addPassword()}
            onChange={(evt) => setName(evt.currentTarget.value)}
          />
          <Text fontSize={"16px"} mt={"10px"}>
            Username
          </Text>
          <Input
            placeholder="Enter username ..."
            size="lg"
            type="text"
            fontSize={"14px"}
            value={username}
            onKeyDown={(e) => e.key === 'Enter' && addPassword()}
            onChange={(evt) => setUserame(evt.currentTarget.value)}
          />
          <Text fontSize={"16px"} mt={"10px"}>
            Password
          </Text>
          <Flex>
            <Input
              placeholder="Enter password ..."
              size="lg"
              fontSize={"14px"}
              type="password"
              value={password}
              onKeyDown={(e) => e.key === 'Enter' && addPassword()}
              onChange={(evt) => setPassword(evt.currentTarget.value)}
            />
            <Box w={"30px"} h={"30px"} ml={"10px"}>
              <Popover placement='right'>
                <PopoverTrigger>
                  <Button h={"100%"} w={"100%"}>
                    <Icon as={MdVpnKey}/>
                  </Button>
                </PopoverTrigger>
                <PopoverContent w={"fit-content"}>
                  <PopoverArrow />
                  <PopoverCloseButton />
                  <PopoverHeader fontSize={"18px"}>Generate Password</PopoverHeader>
                  <PopoverBody overflow={"hidden"} w={"fit-content"}>
                    <Box w={"300px"} h={"30vh"} overflow={"hidden"} p={"5px"}>
                      <GenerationPanel direction="column" action={setPassword} textBtn="validate" />
                    </Box>
                  </PopoverBody>
                </PopoverContent>
              </Popover>
            </Box>
          </Flex>
          <Text fontSize={"16px"} mt={"10px"}>
            Link
          </Text>
          <Input
            placeholder="Enter link ..."
            size="lg"
            fontSize={"14px"}
            type="text"
            value={link}
            onKeyDown={(e) => e.key === 'Enter' && addPassword()}
            onChange={(evt) => setLink(evt.currentTarget.value)}
          />
        </ModalBody>
        <ModalFooter>
          <Button
            mr={3}
            onClick={onClose}
            fontSize={"16px"}
            size={"lg"}
            variant="ghost"
          >
            Close
          </Button>
          <Button
            colorScheme="blue"
            fontSize={"16px"}
            size={"lg"}
            onClick={() => addPassword()}
          >
            Add
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
