import React, { useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import {
  Input,
  Button,
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
  Flex,
  useToast,
  Box,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Switch, 
  Spinner,
  Text
} from "@chakra-ui/react";
import { ArrowBackIcon, ArrowForwardIcon } from "@chakra-ui/icons";
import { toast } from 'react-toastify';
import ReactGA from 'react-ga';
import ReCAPTCHA from "react-google-recaptcha";

import { registerAPI } from "../utils/API";
import {
  cryptData,
  escapeInput,
  hash,
  verifyEmail,
  verifyName,
  verifyPassword as verifyPasswordAPI,
} from "../utils/utils";

import "../style/animation.scss"

export default function Register() {
  const [success, setSuccess] = React.useState<boolean>(false);
  const [name, setName] = React.useState<string>("");
  const [email, setEmail] = React.useState<string>("");
  const [password, setPassword] = React.useState<string>("");
  const [verifyPassword, setVerifyPassword] = React.useState<string>("");
  const [captcha, setCaptcha] = React.useState<string>("");
  const [isCompany, setIsCompany] = React.useState<boolean>(false);
  const [companyName, setCompanyName] = React.useState<string>("");
  const [companyMasterPassword, setCompanyMasterPassword] = React.useState<string>("");
  const [companyVerifyMasterPassword, setCompanyVerifyMasterPassword] = React.useState<string>("");
  const [companyCreditial, setCompanyCreditial] = React.useState<string>("");
  
  const [loading, setLoading] = React.useState<boolean>(false);
  
  const navigate = useNavigate();

  function sendToast(message: string) {
    toast.error(message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      });
    return;
  }

  async function register() {
    if (!name) { // || !verifyName(name)
      sendToast('You need a name !'); // Name does not respect the constraints
      return;
    } else if (!password || !verifyPassword) {
      sendToast('Password is required');
      return;
    } else if (!verifyPasswordAPI(password)) {
      sendToast('Password must follow the instructions provided');
      return;
    }else if (!email) {
      sendToast('Email is required');
      return;
    } else if (!verifyEmail(email)) {
      sendToast('Email invalid');
      return;
    } else if (password !== verifyPassword) {
      sendToast('Password and verify password are not the same');
      return;
    } else if (isCompany) {
      if (!companyName || !companyMasterPassword || !companyCreditial) {
        sendToast('All company information must be filled in');
        return;
      }
      if (!verifyName(companyName)) {
        sendToast('The name of the company must be in one word');
        return;
      }
      if(!verifyPasswordAPI(companyMasterPassword)) {
        sendToast('The company master password must follow the instructions provided');
        return;
      }
      if(companyMasterPassword !== companyVerifyMasterPassword) {
        sendToast('Company master password and Company master password verify password are not the same');
        return;
      }
    }
    var companyObject;
    if (isCompany) {
      companyObject = {
        name: cryptData(escapeInput(companyName), companyMasterPassword),
        credential: companyCreditial,
        hash: hash(companyMasterPassword)
      }
    }
    if (!captcha) {
      sendToast('Fill in the captcha');
      return;
    }
    setLoading(true);
    var response = await registerAPI({ name: escapeInput(name), password: escapeInput(password), email: escapeInput(email), companyObject, token: captcha }).catch((error) => {
      console.log(error);
      sendToast(error.response.data.text);
      setLoading(false);
      return error.response
    });
    if (response.status === 200) {
      ReactGA.event({
        category: 'User',
        action: 'Created an Account'
      });
      toast.success("We've created your account for you.", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
        });
      toast.success("You have received a verification email.", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
        });
      setSuccess(true)
    }
    setLoading(false)
  }

  if (success) {
    return (
      <div
        style={{
          backgroundColor: "#3B556D",
          width: "100%",
          height: "100vh",
          margin: "0px",
          display: "flex",
          flexDirection: "row"
        }}
      >
        <ArrowBackIcon
          w={10}
          h={10}
          position={"absolute"}
          color="white"
          top={"30px"}
          left={"30px"}
          onClick={() => navigate("/", {replace: true})}
          style={{ cursor: "pointer" }}
        />
        <Flex
          w={"30%"}
          height={"72vh"}
          maxWidth={"700px"}
          minWidth={"500px"}
          maxHeight={"72vh"}
          overflowY={"scroll"}
          className="scrollPerso2"
          m={"auto"}
          borderRadius={"10px"}
          bg={"#1C2942"}
          color={"#F1F1F1"}
          p={"30px"}
          direction="column"
        >
          <Box m="auto">
            <div className="success-checkmark">
              <div className="check-icon">
                <span className="icon-line line-tip"></span>
                <span className="icon-line line-long"></span>
                <div className="icon-circle"></div>
                <div className="icon-fix"></div>
              </div>
            </div>
            <Text color="#4CAF50" textAlign="center" fontSize="26px">Success</Text>
            
            <Button rightIcon={<ArrowForwardIcon />} mt={"20px"} colorScheme='teal' variant='solid' size={"lg"} fontSize={"16px"} onClick={() => navigate("/login", {replace: true})}>
              Login
            </Button>
          </Box>
        </Flex>
      </div>
    )
  }

  return (
    <div
      style={{
        backgroundColor: "#3B556D",
        width: "100%",
        height: "100vh",
        margin: "0px",
        display: "flex",
        flexDirection: "row"
      }}
    >
      <ArrowBackIcon
        w={10}
        h={10}
        position={"absolute"}
        color="white"
        top={"30px"}
        left={"30px"}
        onClick={() => (navigate("/", {replace: true}))}
        style={{ cursor: "pointer" }}
      />
      <FormControl
        w={"40%"}
        height={"fit-content"}
        maxWidth={"700px"}
        minWidth={"500px"}
        maxHeight={"80vh"}
        overflowY={"scroll"}
        className="scrollPerso2"
        m={"auto"}
        borderRadius={"10px"}
        bg={"#1C2942"}
        color={"#F1F1F1"}
        p={"30px"}
      >
        <FormLabel fontSize={"18px"} m={"auto"}>
          Register
        </FormLabel>
        <Flex direction={"row"}>
          <Box flex={1} pr={"10px"}>
            <FormLabel fontSize={"15px"} mt={"20px"}>
              Name
            </FormLabel>
            <Input
              type="text"
              size={"lg"}
              fontSize={"15px"}
              value={name}
              onKeyDown={(e) => e.key === 'Enter' && register()}
              onChange={(evt) => setName(evt.currentTarget.value)}
            />
          </Box>
          <Box flex={1} pl={"10px"}>
            <FormLabel fontSize={"15px"} mt={"20px"}>
              Email
            </FormLabel>
            <Input
              type="text"
              size={"lg"}
              fontSize={"15px"}
              value={email}
              onKeyDown={(e) => e.key === 'Enter' && register()}
              onChange={(evt) => setEmail(evt.currentTarget.value)}
            />
          </Box>
        </Flex>
        {/* {<FormHelperText color={"#F1F1F1"} fontSize="10px">
          Has to contains
          <br />- No space
        </FormHelperText>} */}
        <FormLabel mt={"18px"} fontSize={"15px"}>
          Password
        </FormLabel>
        <Input
          type="password"
          size={"lg"}
          fontSize={"15px"}
          value={password}
          onKeyDown={(e) => e.key === 'Enter' && register()}
          onChange={(evt) => setPassword(evt.currentTarget.value)}
        />
        <FormHelperText color={"#F1F1F1"} fontSize="10px">
          Has to contains
          <br />- No space
          <br />- At least 8 characters
          <br />- At least one number
          <br />- At least one capital letter
        </FormHelperText>
        <FormLabel mt={"18px"} fontSize={"15px"}>
          Verify Password
        </FormLabel>
        <Input
          type="password"
          size={"lg"}
          fontSize={"15px"}
          value={verifyPassword}
          onKeyDown={(e) => e.key === 'Enter' && register()}
          onChange={(evt) => setVerifyPassword(evt.currentTarget.value)}
        />
        <Box flex={"1"} mt={"10px"} overflow={"hidden"}>
            <ReCAPTCHA
                theme="dark"
                sitekey={process.env.GOOGLE_CAPTCHA ? process.env.GOOGLE_CAPTCHA : "6Le6zFgkAAAAAHh6w9K2ht3Z4qakI9TA2KU9aHsb"}
                onChange={(value: string | null) => value ? setCaptcha(value) : setCaptcha("")}
                onExpired={() => setCaptcha("")}
            />
        </Box>
        <Accordion mt={"30px"} fontSize={"15px"} allowToggle>
          <AccordionItem>
            <h2>
              <AccordionButton>
                <Box flex='1' textAlign='left' fontSize={"17px"}>
                  Company options (Owner only)
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              <Flex direction="row" mt='20px'>
                <FormLabel fontSize={"14px"}>
                  Create a company ?
                </FormLabel>
                <Switch checked={isCompany} m="auto auto auto 0px" size="lg" colorScheme='teal' onChange={(evt) => setIsCompany(evt.currentTarget.checked)} />
              </Flex>
              <Box color={isCompany ? "white" : "#787878"}>
                <FormLabel mt={"10px"} fontSize={"15px"}>
                  Company Name
                </FormLabel>
                <Input
                  type="text"
                  size={"lg"}
                  fontSize={"15px"}
                  value={companyName}
                  onKeyDown={(e) => e.key === 'Enter' && register()}
                  onChange={(evt) => setCompanyName(evt.currentTarget.value)}
                  isDisabled={!isCompany}
                />
                <FormHelperText color={"#F1F1F1"} fontSize="10px">
                  Has to contains
                  <br />- No space
                </FormHelperText>
                <FormLabel mt={"10px"} fontSize={"15px"}>
                  Company Master Password
                </FormLabel>
                <Input
                  type="password"
                  size={"lg"}
                  fontSize={"15px"}
                  value={companyMasterPassword}
                  onKeyDown={(e) => e.key === 'Enter' && register()}
                  onChange={(evt) => setCompanyMasterPassword(evt.currentTarget.value)}
                  isDisabled={!isCompany}
                />
                <FormHelperText color={"#F1F1F1"} fontSize="10px">
                  Has to contains
                  <br />- No space
                  <br />- At least 8 characters
                  <br />- At least one number
                  <br />- At least one capital letter
                </FormHelperText>
                <FormLabel mt={"10px"} fontSize={"15px"}>
                  Verify Company Master Password
                </FormLabel>
                <Input
                  type="password"
                  size={"lg"}
                  fontSize={"15px"}
                  value={companyVerifyMasterPassword}
                  onKeyDown={(e) => e.key === 'Enter' && register()}
                  onChange={(evt) => setCompanyVerifyMasterPassword(evt.currentTarget.value)}
                  isDisabled={!isCompany}
                />
                <FormLabel mt={"10px"} fontSize={"15px"}>
                  Creation credentials
                </FormLabel>
                <Input
                  type="password"
                  size={"lg"}
                  fontSize={"15px"}
                  value={companyCreditial}
                  onKeyDown={(e) => e.key === 'Enter' && register()}
                  onChange={(evt) => setCompanyCreditial(evt.currentTarget.value)}
                  isDisabled={!isCompany}
                />
                <FormHelperText fontSize="10px" color={isCompany ? "white" : "#787878"}>
                  Business creation credit, provided by us (<a href="/contact">contact us</a>)
                </FormHelperText>
              </Box>
              
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
        <Flex direction={"row-reverse"} justify={"space-between"}>
            <Button
              m={"40px 0px auto auto"}
              colorScheme="teal"
              type="submit"
              size={"lg"}
              isLoading={loading}
              fontSize={"14px"}
              onClick={register}
            >
              Register
            </Button>
          <Flex
            m={"40px auto auto 0px"}
            >
            <Text color={"gray.300"} mr={"5px"}>
              Already have an account ?
            </Text>
            <Text color={"teal"} style={{cursor: "pointer"}} onClick={() => {navigate("/login", {replace: true});
            }}>Login</Text>
          </Flex>
        </Flex>
      </FormControl>
    </div>
  );
}
