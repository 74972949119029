import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import {
  Flex,
  Spacer,
  Center,
  Text,
  Square,
  Box,
  Button,
  useColorModeValue
} from "@chakra-ui/react";
import { CloseIcon } from "@chakra-ui/icons"
import { toast } from "react-toastify";

import GenerationPanel from "../components/GenerationPanel";
import { Folder, Store } from "../models/models";
import { setPage } from "../store/actions";
import { Colors } from "../style/color";


export default function GenerationPage() {
    
    const folder: Folder | undefined = useSelector((state: Store) => state.folder);

    const dispatch = useDispatch();
    
    const bg = useColorModeValue(Colors.bgWhite, Colors.bgDark);

    function copy(password: string, text?: string) {
        navigator.clipboard.writeText(password);
        
        toast.info(text ? text : 'Password copied to clipboard', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
            });
    }

    return (
        <Box w={"100%"} h={"100vh"} position={"relative"}>
            <Box 
                zIndex={"1"}
                position="absolute" 
                top={"15px"} 
                right={"30px"} 
                style={{cursor: "pointer"}} 
                onClick={() => {
                    if (folder) {
                        dispatch(setPage("folder"));
                    } else {
                        dispatch(setPage("main"));
                    }
                }}>
                <CloseIcon />
            </Box>
            <Box h="25vh" w="100%" p="4% 10%">
                <Text fontSize="80px" className="titleFolder">
                    Generate a password
                </Text>
            </Box>
            <Box h={"75vh"} w={"100%"}>
                <Box bg={bg} w={"90%"} m={"auto"} p={"1%"} borderRadius={"10px"}>
                    <GenerationPanel direction="row" action={copy} />
                </Box>
            </Box>
        </Box>
    )
}