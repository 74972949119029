import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import {
  Flex,
  Spacer,
  Center,
  Text,
  Square,
  Box,
  Button,
} from "@chakra-ui/react";

import { Store, User } from "../models/models";
import { getFolders, getPassword, setFolder } from "../store/actions";
import { getFolderAPI } from "../utils/API";
import FoldersView from "./FoldersView";
import PasswordView from "./PasswordsView";
import CryptData from "./CryptData";
import FoldersInfo from "./dashboard/FoldersInfo";

export default function Main() {
  const user: User | undefined = useSelector((state: Store) => state.user);

  return (
    <Box w={"100%"} h={"100%"}>
      <Box h="25vh" w="100%" p="5% 2%" position={"relative"}>
        <Text fontSize="80px" top={"70px"} left={65} pl={10} className="titleFolder" position={"absolute"} zIndex={100}>
          Welcome {user?.name}
        </Text>
      </Box> 
      <Box w="100%" p="4% 9%">
        {/*<FoldersInfo />*/}
      </Box>
    </Box>
  );
}
