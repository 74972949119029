import React, { useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import HomeIcon from "@mui/icons-material/Home";
import EventIcon from "@mui/icons-material/Event";
import DashboardIcon from "@mui/icons-material/Dashboard";
import { ArrowForwardIcon, EditIcon } from "@chakra-ui/icons";
import {
  Flex,
  Spacer,
  Center,
  Text,
  Tooltip,
  Box,
  Button,
  Icon,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverFooter,
  PopoverArrow,
  PopoverCloseButton,
  PopoverAnchor,
  useDisclosure,
  useColorModeValue,
  useColorMode
} from "@chakra-ui/react";
import { CloseIcon } from "@chakra-ui/icons";
import { MdPerson, MdDashboard, MdList, MdBrightness6, MdOutlineNotes, MdEmail, MdVpnKey } from "react-icons/md"
import secureLocalStorage from "react-secure-storage";
import { toast } from "react-toastify";

import {
  getCompany,
  getFolders,
  getPassword,
  getRoles,
  setFolder,
  setPage,
  setUser,
} from "../store/actions";
import FoldersView from "../components/FoldersView";
import Switch from "../components/Switch";

import { Access, Company, Folder, Password, Role, Store, User } from "../models/models";
import { clearStorage, getFromStorage, unCryptData } from "../utils/utils";
import { getUserAPI } from "../utils/API";
import { Colors } from "../style/color";

import notesJson from "../assets/notes.json";

export default function UserLayout() {
  const [loading, setLoading] = React.useState<boolean>(true)

  const page: string = useSelector((state: Store) => state.page);
  const user: User | undefined = useSelector((state: Store) => state.user);
  const company: Company | undefined = useSelector(
    (state: Store) => state.company
  );
  const folders: Folder[] = useSelector((state: Store) => state.folders);
  const roles: Role[] = useSelector((state: Store) => state.roles);
  const passwords: Password[] = useSelector((state: Store) => state.passwords);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { colorMode, toggleColorMode } = useColorMode()
  const bgDrawer = useColorModeValue(Colors.bgWhite, Colors.bgDark);
  const toastColor = useColorModeValue(Colors.toastWhite, Colors.toastDark);

  useEffect(() => {
    var userTemp: User = JSON.parse(getFromStorage("user"));

    getData(userTemp, getFromStorage("token"));
  }, []);

  async function getData(userTemp: User, token: string) {

    var response = await getUserAPI(token, userTemp._id, undefined).catch((e) => {
      clearStorage();
      navigate("/login?message=Expired+session", {replace: true})
      return e.response;
    });
    
    if (response.data.user) {
      secureLocalStorage.setItem("user", JSON.stringify(response.data.user))
      userTemp = response.data.user;

      dispatch(setUser(userTemp));
      dispatch(getCompany(token, userTemp.company));
  
      dispatch(getFolders(token, userTemp.folder, userTemp._id));
      dispatch(getRoles(token, userTemp.role));
      setLoading(false)
    } else {
      clearStorage()
      navigate("/login?message=Expired+session", {replace: true})
    }
  }

  const adminButton = () => {
    if (user?.access && [Access.ADMIN.toString(), Access.OWNER.toString()].includes(user?.access)) {
      return (
        <Link to={"/admin"} style={{ color: 'inherit', textDecoration: 'inherit'}}>
          <Button
            size="md"
            m="auto"
            height="25px"
            width="150px"
            mx="10px"
            border="0px"
            variant={"outline"}
            fontSize={"16px"}
            my={1}
            >
              <Flex direction={"row"} w={"100%"}>
                <Center w={"20%"}>
                  <EditIcon mr={"5px"} />
                </Center>
                <Text w={"80%"}>Admin Panel</Text>
              </Flex>
          </Button>
        </Link>
      );
    } else {
      return;
    }
  };

  return (
    <Box>
      <Flex w={"100%"} h={"100vh"}>
        <Flex flex="1"
          direction={"column"}
          bg={bgDrawer}
          position={"relative"}
          style={{borderTopRightRadius: "3px", borderBottomRightRadius: "3px"}}
          >
          <Box position={"absolute"} top={"7px"} left={"7px"} cursor={"pointer"} onClick={() => dispatch(setPage("patch"))} >
            <Text color={"gray.600"} fontSize={"12px"}>
              v{
                notesJson.items.sort(function (a: any, b: any) {
                  return new Date(b.date).getTime() - new Date(a.date).getTime()
                })[0].version
              }
            </Text>  
          </Box>
          <Flex direction={"column"} w={"100%"} h={"94vh"} overflowX={"auto"} className={"scrollPerso"}>
            <Box w={"100%"} h={"15vh"}>
              <Text
                fontSize={"30px"}
                mx={5}
                mt={10}
                className="titleFolder"
              >
                {unCryptData(company?.name)}
              </Text>
            </Box>
            <Box w={"100%"} my={"1vh"}>
              <Center>
                <Button
                  size="md"
                  m="auto"
                  height="40px"
                  width="90%"
                  border="0px"
                  // borderColor="white"
                  variant={"outline"}
                  fontSize={"16px"}
                  my={1}
                  onClick={async () => {
                    if (page !== "generation") {
                      dispatch(setPage("generation"));
                    }
                  }}
                >
                  <Flex justify="space-between" w="100%">
                    <Center flex={1}>
                      <Icon as={MdVpnKey} />
                    </Center>
                    <Box flex={4} my={"auto"} overflow={"hidden"}>
                      <Text textAlign={"left"}>Generate password</Text>
                    </Box>
                  </Flex>
                </Button>
              </Center>
            </Box>
            <Box w="100%" mt={"2vh"}>
              <FoldersView />
            </Box>
            <Spacer />
          </Flex>
          <Flex w={"100%"} h={"6vh"} fontSize={"1xl"} p={"5%"}>                    
            <Tooltip label="User page" aria-label='A tooltip' fontSize={"15px"} placement={"top-start"}>
              <Flex m="auto" flex="1.5" direction={"row"}>
                <Center mr={"5px"} 
                  style={{cursor: "pointer"}} 
                  onClick={() => dispatch(setPage("user"))}
                  >
                  <Icon as={MdPerson} />
                </Center>
                <Text 
                  position={"relative"}
                  style={{cursor: "pointer"}} 
                  onClick={() => dispatch(setPage("user"))}
                  >
                  {user?.name}
                  <Box bg={colorMode !== 'light' ? "orange.700" : "orange.300"} borderRadius={"10px"} position={"absolute"} top={"-4px"} right={"-22px"} px={"5px"} fontSize={"9px"}>New</Box>
                </Text>
              </Flex>
            </Tooltip>
            <Popover placement='right-end'>
              <PopoverTrigger>
                <Center>
                  <Icon as={MdList} w={25} h={25} />
                </Center>
              </PopoverTrigger>
              <PopoverContent w={"172px"} py={"8px"}>
                <PopoverArrow />
                <Button
                  size="md"
                  m="auto"
                  height="25px"
                  width="150px"
                  mx="10px"
                  border="0px"
                  variant={"outline"}
                  fontSize={"16px"}
                  my={1}
                  onClick={toggleColorMode}
                  >
                    <Flex direction={"row"} w={"100%"}>
                      <Center w={"20%"}>
                        <Icon as={MdBrightness6} mr={"5px"} />
                      </Center>
                      <Text w={"80%"}>{colorMode === 'light' ? 'Dark' : 'Light'} Mode</Text>
                    </Flex>
                </Button>
                <Button
                  size="md"
                  m="auto"
                  height="25px"
                  width="150px"
                  mx="10px"
                  border="0px"
                  variant={"outline"}
                  fontSize={"16px"}
                  my={1}
                  onClick={() => {
                      //dispatch(setFolder(undefined))
                      dispatch(setPage("patch"));
                    }}
                  >
                    <Flex direction={"row"} w={"100%"} position={"relative"}>
                      <Center w={"20%"}>
                        <Icon as={MdOutlineNotes} mr={"5px"} />
                      </Center>
                      <Text w={"80%"}>Patch Notes</Text>
                      <Box bg={colorMode !== 'light' ? "orange.700" : "orange.300"} borderRadius={"10px"} position={"absolute"} top={"-3px"} right={"-18px"} px={"5px"} fontSize={"9px"}>New</Box>
                    </Flex>
                </Button>
                {adminButton()}
                <Button
                  size="md"
                  m="auto"
                  height="25px"
                  width="150px"
                  mx="10px"
                  border="0px"
                  variant={"outline"}
                  fontSize={"16px"}
                  my={1}
                  onClick={() => {
                      navigate('/contact', {replace: false})
                    }}
                  >
                    <Flex direction={"row"} w={"100%"} position={"relative"}>
                      <Center w={"20%"}>
                        <Icon as={MdEmail} mr={"5px"} />
                      </Center>
                      <Text w={"80%"}>Contact us</Text>
                      <Box bg={colorMode !== 'light' ? "orange.700" : "orange.300"} borderRadius={"10px"} position={"absolute"} top={"-3px"} right={"-18px"} px={"5px"} fontSize={"9px"}>New</Box>
                    </Flex>
                </Button>
                <Button
                  colorScheme="red"
                  size="md"
                  m="auto"
                  height="25px"
                  width="150px"
                  mx="10px"
                  border="0px"
                  fontSize={"16px"}
                  my={1}
                  onClick={() => {
                    clearStorage();
                    dispatch(setUser(undefined));
                    toast.info("You have been disconnected", {
                      position: "top-right",
                      autoClose: 5000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                      theme: "dark",
                      });
                    navigate("/", {replace: true})
                  }}
                >
                  Log out
                </Button>
              </PopoverContent>
            </Popover>
          </Flex>
        </Flex>
        <Box flex="4.5">
          <Switch page={page} loading={loading} />
        </Box>
      </Flex>
    </Box>
  );
}

/*

<Button
              size="md"
              m="auto"
              height="5vh"
              width="85%"
              variant="ghost"
              fontSize={"16px"}
              bg={Colors.bgCanard}
              color={Colors.textWhite}
              py={4}
              mt={0}
              mb={15}
              onClick={() => {
                if (page !== "main") {
                  dispatch(setPage("main"));
                }
              }}
            >
              <Flex justify="space-between" w="100%">
                <Center flex={1}>
                  <Icon as={MdDashboard} w={"10"} h={"10"} />
                </Center>
                <Box flex={4}>
                  <Text textAlign={"left"}>Dashboard</Text>
                </Box>
              </Flex>
            </Button>
            */