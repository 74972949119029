import React, {useEffect} from "react";
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import {
  Spinner,
  Box,
  Text,
  Stack,
  Flex,
  Spacer,
  Container,
  Divider,
  Center,
  Input,
  Button,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
  useToast,
  useColorModeValue,
} from "@chakra-ui/react";
import { ArrowBackIcon } from "@chakra-ui/icons";
import  secureLocalStorage  from  "react-secure-storage";
import ReCAPTCHA from "react-google-recaptcha";

import { toast } from 'react-toastify';
import { Colors } from "../style/color";
import { resetPassword } from "../utils/API";


export default function ForgotPasswordPage() {

    const [email, setEmail] = React.useState<string>("");
    const [name, setName] = React.useState<string>("");
    const [captcha, setCaptcha] = React.useState<string>("");
    const [loading, setLoading] = React.useState<boolean>(false);
    
    const navigate = useNavigate();

    const bg = useColorModeValue(Colors.bgWhite, Colors.bgDark);
    const color = useColorModeValue(Colors.textBlue, Colors.textGray);

    async function onChangeCaptcha(value: string | null) {
        if (value) {
            setCaptcha(value);
        }
    }

    async function sendRequest() {
        if (!name || name === "") {
            toast.error("A name must be filled in", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
                });
            return;
        }
        if (!email || email === "") {
            toast.error("An email must be filled in", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
                });
            return;
        }
        if (!captcha || captcha === "") {
            toast.error("Fill in the captcha", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "dark",
              });
            return;
        }

        setLoading(true)
        var response = await resetPassword(captcha, name, email)
            .catch((e) => {
                console.log(e)
                toast.error(e.response.data.text, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                    });
                return e.response
            })

        if (response.status === 200) {
            toast.success(response.data.text, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
                });
        }

        setLoading(false);
        return;
    }

    return (
        <Box w={"100%"} h={"100vh"} position={"relative"}>
            <ArrowBackIcon
                w={10}
                h={10}
                position={"absolute"}
                color={color}
                top={"30px"}
                left={"30px"}
                onClick={() => navigate(-1)}
                style={{ cursor: "pointer", zIndex: 1 }}
                />
            <Box h="25vh" w="100%" p="4% 10%">
                <Text fontSize="80px" className="titleFolder">
                    Forgot Password
                </Text>
            </Box>
            <Box h={"75vh"} w={"100%"} pt={"5vh"}>
                <Box w={"45%"} bg={bg} borderRadius={"8px"} p={"5vh 5%"} m={"auto"}>
                    <FormControl>
                        <Box flex={"1"}>
                            <FormLabel htmlFor="name"  fontSize={"15px"}>Name</FormLabel>
                            <Input 
                                type='text' 
                                size={"lg"}
                                fontSize={"15px"}
                                value={name} 
                                onChange={(e) => setName(e.currentTarget.value)} 
                                />
                        </Box>
                        <Box flex={"1"}>
                            <FormLabel htmlFor="email"  fontSize={"15px"}>Email address</FormLabel>
                            <Input 
                                type='email' 
                                size={"lg"}
                                fontSize={"15px"}
                                value={email} 
                                onChange={(e) => setEmail(e.currentTarget.value)} 
                                />
                        </Box>
                        <Box flex={"1"} mt={"10px"} overflow={"hidden"}>
                            <ReCAPTCHA
                                theme="dark"
                                sitekey={process.env.GOOGLE_CAPTCHA ? process.env.GOOGLE_CAPTCHA : "6Le6zFgkAAAAAHh6w9K2ht3Z4qakI9TA2KU9aHsb"}
                                onChange={onChangeCaptcha}
                                onExpired={() => setCaptcha("")}
                            />
                        </Box>
                        <Flex flexDirection={"row-reverse"}>
                            <Button
                                mt={4}
                                size={"lg"}
                                fontSize={"15px"}
                                colorScheme='teal'
                                isLoading={loading}
                                onClick={sendRequest}
                            >
                                Send
                            </Button>
                        </Flex>
                    </FormControl>
                </Box>
            </Box>
        </Box>
    )
}