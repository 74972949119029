import React from "react";
import jwt_decode from "jwt-decode";
import { Outlet, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { Access, Store } from "../models/models";
import { getFromStorage } from "../utils/utils";

/**
 * ProtectedRoutes
 * Return component asked if user authentificate or redirect to the home page
 * @returns {Component}
 */
const ProtectedRoutes = () => {
  var token = getFromStorage("token")
    
  const navigate = useNavigate();

  if (token) {
    try {
      const user = jwt_decode(token);
      if (!user) {
        console.log("Error in token");
        return null;
      }
      if (window.location.pathname === "/admin") {
        var userHist = getFromStorage("user");
        if (userHist) {
          var userTemp = JSON.parse(userHist);
          if ([Access.ADMIN.toString(), Access.OWNER.toString()].includes(userTemp.access)) {
            return <Outlet />;
          }
        } 
        // navigate("/login", {replace: true});
        window.location.href = "/login";
        return null;
      } else {
        return <Outlet />;
      }
    } catch (error) {
      window.location.href = "/login";
      return null;
    }
    
  } else {
    window.location.href = "/login";
    return null;
  }
};

export default ProtectedRoutes;