import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import {
  Flex,
  Spacer,
  Center,
  Text,
  Square,
  Box,
  Button,
  Input,
  Spinner,
  useColorModeValue
} from "@chakra-ui/react";
import { toast } from 'react-toastify';

import { changePassword as changePasswordAPI } from "../../utils/API";

import { Store, User } from "../../models/models";
import theme from "../../style/theme";
import { Colors } from "../../style/color";
import { getFromStorage, verifyPassword } from "../../utils/utils";

export default function ChangePassword() {
    const [passwordVerify, setPasswordVerify] = React.useState<string>("");
    const [passwordOne, setPasswordOne] = React.useState<string>("");
    const [passwordTwo, setPasswordTwo] = React.useState<string>("");
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    
    const bg = useColorModeValue(Colors.bgWhite, Colors.bgDark);

    const user: User | undefined = useSelector((state: Store) => state.user);

    async function changePassword() {

        if (isLoading) {
            return;
        }

        setIsLoading(true)

        if (passwordOne === "" || passwordTwo === "" || passwordVerify === "") {
            toast.error("All fields must be filled in", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
                });
            setIsLoading(false)
            return;
        }
        if (passwordOne !== passwordTwo) {
            toast.error("New passwords are not the same", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
                });
            setIsLoading(false)
            return;
        }
        if (!verifyPassword(passwordOne)) {
            toast.error('Password must follow the instructions provided', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
                });            
        }

        var response = user && await changePasswordAPI(getFromStorage("token"), user, passwordVerify, passwordOne).catch((e: any) => {return e.response})

        if (response.status === 200) {
            toast.info("Password change with success", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
                });
            setIsLoading(false)
        } else {
            toast.error(response.data.text, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
                });
            setIsLoading(false)
        }

    }

    if (!user) {
        return (
            <Center>
                <Spinner />
            </Center>
        );
    }

    return (
        <Flex w={"100%"} h={"100%"} borderRadius={"10px"} bg={bg} p={"5%"} direction={"column"} justify={"space-between"}>
            <Text fontSize={"20px"} mb={"10px"}>Change Password :</Text>
            <Text>Current password:</Text>
            <Input
                type="password"
                size={"lg"}
                fontSize={"15px"}
                value={passwordVerify}
                onKeyDown={(e) => e.key === 'Enter' && changePassword()}
                onChange={(evt) => setPasswordVerify(evt.currentTarget.value)}
                />
            <Text mt={"10px"}>New password:</Text>
            <Input
                type="password"
                size={"lg"}
                fontSize={"15px"}
                value={passwordOne}
                onPaste={(e: any) => {
                    e.preventDefault();
                    return false;
                }}
                onCopy={(e: any) => {
                    e.preventDefault();
                    return false;
                }}
                onKeyDown={(e) => e.key === 'Enter' && changePassword()}
                onChange={(evt) => setPasswordOne(evt.currentTarget.value)}
                />
            <Text fontSize="10px">
                Has to contains
                <br />- No space
                <br />- At least 8 characters
                <br />- At least one number
                <br />- At least one capital letter
            </Text>
            <Text mt={"10px"}>Verify new password:</Text>
            <Input
                type="password"
                size={"lg"}
                fontSize={"15px"}
                value={passwordTwo}
                onPaste={(e: any) => {
                    e.preventDefault();
                    return false;
                }}
                onCopy={(e: any) => {
                    e.preventDefault();
                    return false;
                }}
                onKeyDown={(e) => e.key === 'Enter' && changePassword()}
                onChange={(evt) => setPasswordTwo(evt.currentTarget.value)}
                />
            <Flex direction={"row-reverse"}>
                <Button
                    m={"40px 0px auto auto"}
                    colorScheme="teal"
                    type="submit"
                    size={"lg"}
                    isLoading={isLoading}
                    fontSize={"14px"}
                    onClick={changePassword}
                >
                    Change password
                </Button>
            </Flex>
        </Flex>
    )
}