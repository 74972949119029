// theme.ts

// 1. import `extendTheme` function
import { extendTheme, type ThemeConfig, defineStyleConfig } from '@chakra-ui/react'
import { mode } from '@chakra-ui/theme-tools'
import { Colors } from './color'

// 2. Add your color mode config
const config: ThemeConfig = {
  initialColorMode: 'light',
  useSystemColorMode: false,
}

const styles: any = {
    global: (props: any) => ({
      body: {
        bg: mode(Colors.bgGray, Colors.bgDarkMain)(props),
        color: mode(Colors.textBlue, Colors.textGray)(props),
      },
    }),
}

// 3. extend the theme
const theme = extendTheme({ 
    config: config, 
    styles: styles,
    fonts: {
      body: `'Kanit', sans-serif`,
    },
    components: {
        Button: {
        baseStyle: {
            bg: "#FFFFFF00"
        },
        variants: {
                "outline":{
                    color: mode(Colors.textBlue, Colors.textGray)
                }
            }
        },
    },
})

export default theme