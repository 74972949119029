import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import {
  Flex,
  Spacer,
  Center,
  Text,
  Square,
  Box,
  Button,
  Spinner,
  HStack,
  Tag,
  Icon,
  useColorModeValue,
  useDisclosure
} from "@chakra-ui/react";
import { MdModeEditOutline, MdWarning } from "react-icons/md"

import { Store, User } from "../../models/models";
import { Colors } from "../../style/color";
import ModalEdit from "../admin/modals/ModalEdit";
import { updateObjectAPI, verifyEmail as verifyEmailAPI } from "../../utils/API";
import { getFromStorage } from "../../utils/utils";
import { updateUser } from "../../store/actions";
import { toast } from "react-toastify";

export default function UserInfo() {
    const [defaultValue, setDefaultValue] = React.useState<string>("");

    const user: User | undefined = useSelector((state: Store) => state.user);
    
    const bg = useColorModeValue(Colors.bgWhite, Colors.bgDark);
    const color = useColorModeValue(Colors.textBlue, Colors.textGray);

    const dispatch = useDispatch();

    const { isOpen: isOpenEdit, onOpen: onOpenEdit, onClose: onCloseEdit } = useDisclosure();

    async function editEmail(email: string) {
        if (user) {
            var newUser = user;
            newUser.email = email;
            var response = await updateObjectAPI(getFromStorage("token"), "user", {user: newUser}).catch((e) => {return e.response})
            if (response.status === 200) {
                dispatch(updateUser(response.data.user))   
            }
        }
    }

    async function verifyEmail() {
        if (!user) {
            return;
        }

        if (!user.email) {
            toast.error("A email must be filled in", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
                });
            return;
        }

        var response = await verifyEmailAPI(getFromStorage("token"), user).catch((e) => {return e.response})

        if (response.status === 200) {
            toast.success("We sent you a verification email", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
                });
        }
    }

    if (!user) {
        return (
            <Center>
                <Spinner />
            </Center>
        );
    }

    return (
        <Box w={"100%"} h={"100%"}>
            <Flex w={"100%"} h={"100%"} overflow={"hidden"} borderRadius={"10px"} bg={bg} p={"10% 5%"} direction={"column"} justify={"space-between"}>
                <Box>
                    <Flex>
                        <Text>Account</Text> 
                        <Center>
                            {
                                user?.account_validate ? 
                                <Flex color={"teal"} borderRadius={"8px"} px={"5px"} ml={"8px"}>
                                    <Text fontSize={"12px"}>Verify</Text>
                                </Flex>
                                :
                                <Flex color={"red.300"} borderRadius={"8px"} px={"5px"} ml={"8px"}>
                                    <Text fontSize={"12px"} margin={"auto"}>Not verify</Text>
                                </Flex>
                            }
                        </Center>
                    </Flex>
                </Box>
                <Box>
                    <Flex>
                        <Text fontSize={"18px"}>
                            Email
                            <Icon 
                                as={MdModeEditOutline} 
                                color={color} 
                                ml={"5px"} 
                                style={{cursor: "pointer"}} 
                                onClick={() => {
                                    setDefaultValue(user?.email);
                                    onOpenEdit()
                                }} 
                                />
                        </Text> 
                    </Flex>
                    {
                        user?.email ? 
                        <Flex>
                            <Text mr={"10px"}>{user?.email}</Text>
                        </Flex> 
                        : 
                        <Flex color={"gray.600"}>
                            <Icon as={MdWarning} m={"auto"}/>
                            <Text >
                                {"no email, functions like \"forgot password\" will not be available"} 
                            </Text> 
                        </Flex>
                    }
                    {
                        user?.account_validate ? 
                        <Text></Text>
                        :
                        <Button
                            m={"10px 0px auto auto"}
                            colorScheme="teal"
                            type="submit"
                            size={"lg"}
                            fontSize={"14px"}
                            isActive={user?.email ? true : false}
                            onClick={verifyEmail}
                        >
                            Click to validate your account
                        </Button>
                    }
                </Box>
                <Box>
                    <Text fontSize={"18px"}>
                        Role
                    </Text>
                    <HStack spacing={4}>
                        <Tag
                            size={"lg"}
                            variant="subtle"
                            colorScheme="green"
                            fontSize={"13px"}
                            padding={"5px"}
                            >
                            {user?.access.charAt(0).toUpperCase() + user?.access.slice(1)}
                        </Tag>
                    </HStack>
                </Box>
            </Flex>
            <ModalEdit onClose={onCloseEdit} isOpen={isOpenEdit} action={editEmail} title={"Edit email"} text={"Email :"} btnText={"Update"} defaultValue={defaultValue} />
        </Box>
    )
}