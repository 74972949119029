import React, { useEffect, useState } from "react";
import { ArrowForwardIcon } from "@chakra-ui/icons";
import { useDispatch, useSelector } from "react-redux";
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Box,
  Button,
  ButtonGroup,
  Center,
  Spinner,
  Flex,
  Text,
  Tooltip,
  useDisclosure,
  useColorModeValue,
  Icon
} from "@chakra-ui/react";
import { AddIcon } from "@chakra-ui/icons";
import { MdFolder, MdFolderOpen, MdSupervisorAccount, MdPerson } from "react-icons/md";

import { Access, Folder, Store } from "../models/models";
import { setFolder, getPassword, setPage, addFolder, addFolderPerso, addFolderCompany } from "../store/actions";
import { getFromStorage, unCryptData } from "../utils/utils";
import ModalAddFolder from "./admin/modals/ModalAddFolder";
import { useToast } from "react-toastify";

export default function FoldersView() {
  const page: string = useSelector((state: Store) => state.page);
  const user = useSelector((state: Store) => state.user);
  const company = useSelector((state: Store) => state.company);
  const folders = useSelector((state: Store) => state.folders);
  const foldersPerso = useSelector((state: Store) => state.foldersPerso);
  const folder = useSelector((state: Store) => state.folder)
  
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isOpen: isOpen2, onOpen: onOpen2, onClose: onClose2 } = useDisclosure();

  const dispatch = useDispatch();
  
  function addFolderPersoFunction(folderObject: Folder) {
    folderObject.user = user?._id
    dispatch(addFolderPerso(getFromStorage("token"), folderObject, user));
  }

  function addFolderCompanyFunction(folderObject: Folder) {
    folderObject.company = company?._id
    dispatch(addFolderCompany(getFromStorage("token"), folderObject, user));
  }

  const iconFolder = (folderItem: Folder) => {
    return (folder && folderItem) && (folder?._id === folderItem._id) ? 
      <svg xmlns="http://www.w3.org/2000/svg" width="26px" height="26px" viewBox="0 0 48 48"><path fill={unCryptData(folderItem.color)+"99"} d="M38,12H22l-4-4H8c-2.2,0-4,1.8-4,4v24c0,2.2,1.8,4,4,4h31c1.7,0,3-1.3,3-3V16C42,13.8,40.2,12,38,12z"/><path fill={unCryptData(folderItem.color)} d="M42.2,18H15.3c-1.9,0-3.6,1.4-3.9,3.3L8,40h31.7c1.9,0,3.6-1.4,3.9-3.3l2.5-14C46.6,20.3,44.7,18,42.2,18z"/></svg>
      : 
      <Icon w={"10"} h={"10"} as={MdFolder} color={unCryptData(folderItem.color)+'E6'} /> 
  }

  const addFolderButton = () => {
    if (user && [Access.USER.toString(), Access.ADMIN.toString(), Access.OWNER.toString()].includes(user?.access)) {
      return (
        <Center>
          <Tooltip label="Create a company folder" aria-label='A tooltip' fontSize={"15px"} placement='top'>
            <AddIcon my="auto" w="10px" h="10px" onClick={onOpen2} style={{cursor: "pointer"}} />
          </Tooltip>
        </Center>
      )
    }
  }

  if (!folders) {
    return (
      <Center>
        <Spinner />
      </Center>
    );
  }

  return (
    <Box w="100%" height={"100%"} overflow={"auto"} className="scrollPerso3">
      <Flex w={"94%"} pl={"5%"} mb={"10px"} direction={"row"} justify={"space-between"}>
        <Center>
          <Icon as={MdPerson} w="10" h="10" />
          <Text fontSize="16px" ml={"5px"} className="titleFolder">Perso</Text>
        </Center>
        <Center>
          <Tooltip label="Create personal folder" aria-label='A tooltip' fontSize={"15px"} placement='top'>
            <AddIcon my="auto" w="10px" h="10px" onClick={onOpen} style={{cursor: "pointer"}} />
          </Tooltip>
        </Center>
      </Flex>
      {foldersPerso.map((folderItem: Folder, index: number) => {
        return (
          <Center key={folderItem._id}>
            <Button
              size="md"
              m="auto"
              height="40px"
              width="90%"
              border="0px"
              // borderColor="white"
              variant={"outline"}
              fontSize={"16px"}
              my={1}
              onClick={async () => {
                if (page !== "folder") {
                  dispatch(setPage("folder"));
                }
                dispatch(setFolder(foldersPerso[index]));
                dispatch(getPassword(getFromStorage("token"), foldersPerso[index]._id));
              }}
            >
              <Flex justify="space-between" w="100%">
                <Center flex={1}>
                  {iconFolder(folderItem)}
                </Center>
                <Box flex={4} my={"auto"} overflow={"hidden"}>
                  <Text textAlign={"left"}>{unCryptData(folderItem.name)}</Text>
                </Box>
              </Flex>
            </Button>
          </Center>
        );
      })}
      <Flex w={"94%"} pl={"5%"} mb={"10px"} direction={"row"} mt="30px" justify={"space-between"}>
        <Center>
          <Icon as={MdSupervisorAccount} w="10" h="10" />
          <Text fontSize="16px" ml={"5px"} className="titleFolder">Company</Text>
        </Center>
        {addFolderButton()}
      </Flex>
      {folders.map((folderItem: Folder, index: number) => {
        return (
          <Center key={folderItem._id}>
            <Button
              size="md"
              m="auto"
              height="40px"
              width="90%"
              border="0px"
              // borderColor="white"
              variant={"outline"}
              fontSize={"16px"}
              my={1}
              onClick={async () => {
                if (page !== "folder") {
                  dispatch(setPage("folder"));
                }
                dispatch(setFolder(folders[index]));
                dispatch(getPassword(getFromStorage("token"), folders[index]._id));
              }}
            >
              <Flex justify="space-between" w="100%">
                <Center flex={1}>
                  {iconFolder(folderItem)}
                </Center>
                <Box flex={4} my={"auto"} overflow={"hidden"}>
                  <Text textAlign={"left"}>{unCryptData(folderItem.name)}</Text>
                </Box>
              </Flex>
            </Button>
          </Center>
        );
      })}
      <ModalAddFolder onClose={onClose} isOpen={isOpen} user={user} addFolder={addFolderPersoFunction} text={`Add a personal folder for ${user?.name}`} />
      <ModalAddFolder onClose={onClose2} isOpen={isOpen2} user={user} addFolder={addFolderCompanyFunction} text={`Add a folder to the company`} />
    </Box>
  );
}
