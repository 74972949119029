import React, { useEffect } from "react";
import { Provider } from "react-redux";
import { makeStyles } from "@mui/styles";
import { createTheme, Theme, ThemeProvider } from "@mui/material/styles";
import { ChakraProvider, ColorModeScript } from "@chakra-ui/react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactGA from 'react-ga';

import theme from "./style/theme";

import store from "./store/store";
import Login from "./components/Login";
import HomePage from "./pages/HomePage";
import ProtectedRoutes from "./components/ProtectedRoutes";
import Admin from "./pages/AdminPage";
import Register from "./components/Register";

import "./App.css";
import NotFound from "./components/NotFoud";
import VerifyAccountPage from "./pages/VerifyAccountPage";
import ContactPage from "./pages/ContactPage";
import PoliciesPage from "./pages/PoliciesPages";
import AppPage from "./pages/AppPage";
import ForgotPasswordPage from "./pages/ForgotPasswordPage";

const TRACKING_ID = "G-EZBKX3MH4N";
ReactGA.initialize(TRACKING_ID);

function App() {

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search + " DEV");
  })

  return (
    <ChakraProvider theme={theme}>
      <ColorModeScript initialColorMode={theme.config.initialColorMode} />
      <Provider store={store}>
        <Router>
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/login" element={<Login />} />
            <Route path="/forgotpassword" element={<ForgotPasswordPage />} />
            <Route path="/register" element={<Register />} />
            <Route path="/verifyaccount" element={<VerifyAccountPage />} />
            <Route path="/contact" element={<ContactPage />} />
            <Route path="/policies" element={<PoliciesPage />} />
            <Route element={<ProtectedRoutes />}>
              <Route path="/app" element={<AppPage />} />
              <Route path="/admin" element={<Admin />} />
            </Route>
            <Route path='*' element={<NotFound />}/>
          </Routes>
        </Router>
      </Provider>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
        />
    </ChakraProvider>
  );
}

export default App;
