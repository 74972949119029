import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  useNavigate,
} from "react-router-dom";
import {
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Box,
  Center,
  Spacer,
  Text,
  Flex,
  Button,
  Spinner,
  useColorModeValue,
} from "@chakra-ui/react";
import UserLayout from "../layout/UserLayout";

export default function AppPage() {

  return (
    <Box w={"100%"} h={"100vh"} position={"relative"}>
        <UserLayout />
    </Box>
  );
}
