import React, {useEffect} from "react";
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import {
  Spinner,
  Box,
  Text,
  Stack,
  Flex,
  Spacer,
  Container,
  Divider,
  Center,
  Input,
  Button,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
  useToast,
} from "@chakra-ui/react";
import { ArrowBackIcon } from "@chakra-ui/icons";
import  secureLocalStorage  from  "react-secure-storage";
import { toast } from 'react-toastify';

import { Store, User } from "../models/models";
import { setUser } from "../store/actions";
import { loginAPI } from "../utils/API";

import "../style/styles.css";
import { hash } from "../utils/utils";

export default function Login() {
  const [name, setName] = React.useState<string>("");
  const [password, setPassword] = React.useState<string>("");
  const [password_maitre, setPasswordMaitre] = React.useState<string>("");
  const [loading, setLoading] = React.useState<boolean>(false);

  const user: User | undefined = useSelector((state: Store) => state.user);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    const message = urlParams.get('message')

    if(message) {
      toast.info(message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
        });
    }
  }, [])

  async function forgotPassword(event: any) {
    event.preventDefault();
    navigate("/forgotpassword", {replace: false})
  }

  async function handleSubmit(event: any) {
    event.preventDefault();
    if (name && password && password_maitre && !loading) {
      setLoading(true);
      var response: any = await loginAPI({
        name,
        password,
        hash: hash(password_maitre),
      }).catch((e) => {
        toast.error(e.response.data.text, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
          });
        setLoading(false);
        return e.response;
      });
      if (response.status === 200) {
        setLoading(false);
        dispatch(setUser(response.data.user));
        secureLocalStorage.setItem("user", JSON.stringify(response.data.user));
        secureLocalStorage.setItem("token", response.data.token);
        secureLocalStorage.setItem("password_maitre", password_maitre);
        toast.success("Welcome back", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
          });
        navigate('/app', {replace: true});
      }
    } else {
      toast.error('Missing information', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
        });
    }
    setLoading(false);
  }

  return (
    <div
      style={{
        backgroundColor: "#3B556D",
        width: "100%",
        height: "100vh",
        margin: "0px",
        paddingTop: "15vh",
      }}
    >
      <ArrowBackIcon
        w={10}
        h={10}
        position={"absolute"}
        color="white"
        top={"30px"}
        left={"30px"}
        onClick={() => navigate('/', {replace: true})}
        style={{ cursor: "pointer" }}
      />
      <FormControl
        w={"30%"}
        maxWidth={"700px"}
        minWidth={"500px"}
        m={"auto"}
        borderRadius={"10px"}
        bg={"#1C2942"}
        color={"#F1F1F1"}
        p={"30px"}
      >
        <FormLabel fontSize={"18px"} m={"auto"}>
          Login
        </FormLabel>
        <FormLabel fontSize={"15px"} mt={"20px"}>
          Name
        </FormLabel>
        <Input
          type="text"
          size={"lg"}
          fontSize={"15px"}
          value={name}
          onKeyDown={(e) => e.key === 'Enter' && handleSubmit(e)}
          onChange={(evt) => setName(evt.currentTarget.value)}
        />
        <FormLabel mt={"20px"} fontSize={"15px"}>
          Password
        </FormLabel>
        <Input
          type="password"
          size={"lg"}
          fontSize={"15px"}
          value={password}
          onKeyDown={(e) => e.key === 'Enter' && handleSubmit(e)}
          onChange={(evt) => setPassword(evt.currentTarget.value)}
        />
        <Flex direction={"row"}>
          <Text 
            color={"teal"} 
            m={"5px 5px auto auto"} 
            fontSize={"10px"}
            onClick={forgotPassword}
            style={{cursor: "pointer"}}
            >
            Forgot your password?
          </Text>
        </Flex>
        <FormLabel mt={"20px"} fontSize={"15px"}>
          Master Password
        </FormLabel>
        <Input
          type="password"
          size={"lg"}
          fontSize={"15px"}
          value={password_maitre}
          onKeyDown={(e) => e.key === 'Enter' && handleSubmit(e)}
          onChange={(evt) => setPasswordMaitre(evt.currentTarget.value)}
        />
        <Flex direction={"row-reverse"} justify={"space-between"}>
            <Button
              m={"40px 0px auto auto"}
              colorScheme="teal"
              type="submit"
              size={"lg"}
              isLoading={loading}
              fontSize={"14px"}
              onClick={handleSubmit}
            >
              Login
            </Button>
          <Flex
            m={"40px auto auto 0px"}
            >
            <Text color={"gray.300"} mr={"5px"}>
              You don't have a account ?
            </Text>
            <Text color={"teal"} style={{cursor: "pointer"}} onClick={() => {navigate("/register", {replace: true});
            }}>Register</Text>
          </Flex>
        </Flex>
      </FormControl>
    </div>
  );
}
