import React from "react";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import {
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Box,
  Center,
  Spacer,
  Text,
  Flex,
  Button,
} from "@chakra-ui/react";
import { ArrowBackIcon, ArrowForwardIcon } from "@chakra-ui/icons";

export default function Home() {
  return (
    <Box w={"100%"} h={"100vh"}>
      <Flex padding={"10px 30px"} h={"7vh"} bg="#1C2942" direction={"row"}>
        <Box
          flex={1}
          m={"auto auto auto 10px"}
          color="white"
          className="titleFolder"
          fontSize={"24px"}
        >
          Passap
        </Box>
        <Flex flex={1} direction={"row-reverse"}>
          <Box ml={"10px"}>
            <Link to={"/register"} style={{ textDecoration: "none" }} replace={false}>
              <Button
                m={"auto"}
                colorScheme="teal"
                type="submit"
                size={"lg"}
                fontSize={"14px"}
              >
                Register
              </Button>
            </Link>
          </Box>
          <Box>
            <Link to={"/login"} style={{ textDecoration: "none" }} replace={false}>
              <Button
                m={"auto"}
                colorScheme="teal"
                type="submit"
                size={"lg"}
                fontSize={"14px"}
              >
                Login
              </Button>
            </Link>
          </Box>
        </Flex>
      </Flex>
      <Box w={"100%"} h={"93vh"}>
        <Flex w={"100%"} h={"40vh"} bg={"#3B556D"}>
          <Link to={"/app"} style={{ textDecoration: "none", margin: "auto" }} replace={true}>
            <Button
              colorScheme={"teal"}
              p={"30px 40px"}
              h={"7vh"}
              m={"auto"}
              fontSize={"20px"}
            >
              App
              <ArrowForwardIcon ml={"20px"} />
            </Button>
          </Link>
        </Flex>
        <Flex direction={"row"}>
          <Link to={"/contact"} style={{ textDecoration: "none", margin: "auto" }} replace={false}>
            <Button
              colorScheme={"teal"}
              p={"10px 20px"}
              m={"10px auto"}
              fontSize={"15px"}
            >
              Contact us
            </Button>
          </Link>
          <Link to={"/policies"} style={{ textDecoration: "none", margin: "auto" }} replace={false}>
            <Text color={"teal"} fontSize={"15px"}>Policies</Text>
          </Link>
        </Flex>
      </Box>
    </Box>
  );
}
