import React from "react";
import { ThunkAction } from "redux-thunk";
import { Action } from "redux";

import { Company, Folder, Password, Role, Store, User } from "../models/models";
import {
  addObjectAPI,
  getCompanyAPI,
  getFolderAPI,
  getObjectAPI,
  getPasswordAPI,
  getRoleAPI,
  getUserAPI,
  removeObjectAPI,
  updateObjectAPI
} from "../utils/API";

export const SET_FOLDERS = "SET_FOLDERS";
export const SET_FOLDERS_PERSO = "SET_FOLDERS_PERSO";
export const ADD_FOLDER_PERSO = "ADD_FOLDER_PERSO";
export const UPDATE_FOLDER_PERSO = "UPDATE_FOLDER_PERSO";
export const DELETE_FOLDER = "DELETE_FOLDER";
export const UPDATE_FOLDER = "UPDATE_FOLDER";
export const CREATE_FOLDER = "CREATE_FOLDER";
export const SET_USER = "SET_USER";
export const DELETE_USER = "DELETE_USER";
export const UPDATE_USER = "UPDATE_USER";
export const CREATE_USER = "CREATE_USER";
export const SET_PASSWORD = "SET_PASSWORD";
export const SET_FOLDER = "SET_FOLDER";
export const ADD_FOLDER = "ADD_FOLDER";
export const SET_COMPANY = "SET_COMPANY";
export const SET_ROLES = "SET_ROLES";
export const SET_PAGE = "SET_PAGE";
export const SET_USERS_ADMIN = "SET_USERS_ADMIN";
export const SET_FOLDERS_ADMIN = "SET_FOLDERS_ADMIN";
export const SET_ROLES_ADMIN = "SET_ROLES_ADMIN";
export const SET_PASSWORDS_ADMIN = "SET_PASSWORDS_ADMIN";
export const SET_COMPANIES_ADMIN = "SET_COMPANIES_ADMIN";

export type ActionTypes =
  | { type: typeof SET_FOLDERS; payload: Folder[] }
  | { type: typeof SET_FOLDERS_PERSO; payload: Folder[] }
  | { type: typeof ADD_FOLDER_PERSO; payload: Folder }
  | { type: typeof UPDATE_FOLDER_PERSO; payload: Folder }
  | { type: typeof DELETE_FOLDER; payload: Folder }
  | { type: typeof UPDATE_FOLDER; payload: Folder }
  | { type: typeof CREATE_FOLDER; payload: Folder }
  | { type: typeof SET_USER; payload: User | undefined }
  | { type: typeof DELETE_USER; payload: User }
  | { type: typeof UPDATE_USER; payload: User }
  | { type: typeof CREATE_USER; payload: User }
  | { type: typeof SET_PASSWORD; payload: Password[] }
  | { type: typeof SET_FOLDER; payload: Folder | undefined }
  | { type: typeof ADD_FOLDER; payload: Folder }
  | { type: typeof SET_COMPANY; payload: Company }
  | { type: typeof SET_ROLES; payload: Role[] }
  | { type: typeof SET_PAGE; payload: string }
  | { type: typeof SET_USERS_ADMIN; payload: User[] }
  | { type: typeof SET_FOLDERS_ADMIN; payload: Folder[] }
  | { type: typeof SET_ROLES_ADMIN; payload: Role[] }
  | { type: typeof SET_PASSWORDS_ADMIN; payload: Password[] }
  | { type: typeof SET_COMPANIES_ADMIN; payload: Company[] };

export const setFolders = (folders: Folder[]): ActionTypes => ({
  type: SET_FOLDERS,
  payload: folders,
});

export const setFoldersPerso = (folders: Folder[]): ActionTypes => ({
  type: SET_FOLDERS_PERSO,
  payload: folders,
});

export const deleteFolder = (user: User, folder: Folder): ActionTypes => ({
  type: DELETE_FOLDER,
  payload: folder,
});

export const updateFolder = (user: User, folder: Folder): ActionTypes => ({
  type: UPDATE_FOLDER,
  payload: folder,
});

export const createFolder = (user: User, folder: Folder): ActionTypes => ({
  type: CREATE_FOLDER,
  payload: folder,
});

export const setUser = (user: User | undefined): ActionTypes => ({
  type: SET_USER,
  payload: user,
});

export const deleteUser = (user: User): ActionTypes => ({
  type: DELETE_USER,
  payload: user,
});

export const updateUser = (user: User): ActionTypes => ({
  type: UPDATE_USER,
  payload: user,
});

export const createUser = (user: User): ActionTypes => ({
  type: CREATE_USER,
  payload: user,
});

export const setPassword = (passwords: Password[]): ActionTypes => ({
  type: SET_PASSWORD,
  payload: passwords,
});

export const setFolder = (folder: Folder | undefined): ActionTypes => ({
  type: SET_FOLDER,
  payload: folder,
});

export const addFolder = (folder: Folder): ActionTypes => ({
  type: ADD_FOLDER,
  payload: folder,
});

export const addFolderPersoStore = (folder: Folder): ActionTypes => ({
  type: ADD_FOLDER_PERSO,
  payload: folder,
});

export const updateFolderPersoStore = (folder: Folder): ActionTypes => ({
  type: UPDATE_FOLDER_PERSO,
  payload: folder,
});

export const setCompany = (company: Company): ActionTypes => ({
  type: SET_COMPANY,
  payload: company,
});

export const setRoles = (roles: Role[]): ActionTypes => ({
  type: SET_ROLES,
  payload: roles,
});

export const setPage = (page: string): ActionTypes => ({
  type: SET_PAGE,
  payload: page,
});

export const setUsers = (users: User[]): ActionTypes => ({
  type: SET_USERS_ADMIN,
  payload: users,
});

export const setFoldersAdmin = (folders: Folder[]): ActionTypes => ({
  type: SET_FOLDERS_ADMIN,
  payload: folders,
});

export const setPasswordsAdmin = (passwords: Password[]): ActionTypes => ({
  type: SET_PASSWORDS_ADMIN,
  payload: passwords,
});

export const setRolesAdmin = (roles: Role[]): ActionTypes => ({
  type: SET_ROLES_ADMIN,
  payload: roles,
});

export const setCompaniesAdmin = (companies: Company[]): ActionTypes => ({
  type: SET_COMPANIES_ADMIN,
  payload: companies,
});

export const getCompany =
  (
    token: string,
    company: string
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  async (dispatch: any) => {
    const response: any = await getCompanyAPI(token, company).catch((e) =>{
      console.log(e)
      return e.response
    });
    if (response.status === 200) {
      const companyFind: Company = response.data.company;
      dispatch(setCompany(companyFind));
    }
  };

export const getFolders =
  (
    token: string,
    folders: string[],
    user?: string
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  async (dispatch: any) => {

    if (folders.length <= 0) {
      return;
    }

    const response: any = await getFolderAPI(token, folders, undefined, undefined).catch((e) => {
      console.log(e)
      return e.response
    });

    if (response.status === 200) {
      var foldersResp: Folder[] = [];
      var foldersPerso: Folder[] = [];
      response.data.folder.forEach((element: any) => {
        if (element.user && user && element.user === user) {
          foldersPerso.push(element)
        } else {
          foldersResp.push(element)
        }
      });
      //dispatch(setFolder(foldersResp[0]));
      //dispatch(getPassword(token, foldersResp[0]._id));
      dispatch(setFolders(foldersResp));
      dispatch(setFoldersPerso(foldersPerso));
    } else {
      dispatch(setFolders([]));
      dispatch(setFoldersPerso([]));
    }
  };

  export const addFolderPerso = 
    (
      token: string, 
      folder: Folder,
      user?: User,
    ): ThunkAction<void, Store, unknown, Action<string>> =>
  async (dispatch: any) => {
    const response: any = await addObjectAPI(token, "folder", { folder }).catch((e) => {
      console.log(e)
      return e.response
    });

    if (response.status === 200 && user) {

      user.folder.push(response.data.create._id)

      var responseUser = await updateObjectAPI(token, "user", {user}).catch((e) => {
        console.log(e)
        return e.response
      });

      if (responseUser.status === 200) {
        console.log(responseUser)
        dispatch(setUser(responseUser.data.user));
        // dispatch(getFolders(token, responseUser.data.user.folder, responseUser.data.user._id));
        // dispatch(getRoles(token, responseUser.data.user.role));
        dispatch(addFolderPersoStore(response.data.create)) // TODO
        dispatch(setPage("folder"));
        dispatch(setFolder(response.data.create));
        dispatch(getPassword(token, response.data.create._id));
      }
    }
  }

export const updateFolderPerso = 
    (
      token: string, 
      folder: Folder,
      user?: User,
    ): ThunkAction<void, Store, unknown, Action<string>> =>
  async (dispatch: any) => {
    const response: any = await updateObjectAPI(token, "folder", { folder } ).catch((e) => {
      console.log(e)
      return e.response
    });
    
    if (response.status === 200) {
      if (user) {
        dispatch(getFolders(token, user.folder, user._id))
        dispatch(getRoles(token, user.role))
        // dispatch(updateFolderPersoStore(folder))
      }
    }
  }

export const removeFolderPerso = 
    (
      token: string, 
      folder: Folder,
      user?: User,
    ): ThunkAction<void, Store, unknown, Action<string>> =>
  async (dispatch: any) => {
    const response: any = await removeObjectAPI(token, "folder", { folder: folder._id } ).catch((e) => {
      console.log(e)
      return e.response
    });
    
    if (response.status === 200) {
      if (user) {
        // dispatch(getFolders(token, user.folder, user._id))
        // dispatch(getRoles(token, user.role))
        dispatch(deleteFolder(user, folder))
      }
    }
  }

export const addFolderCompany = 
  (
    token: string, 
    folder: Folder,
    user?: User,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  async (dispatch: any) => {
    const response: any = await addObjectAPI(token, "folder", { folder } ).catch((e) => {
      console.log(e)
      return e.response
    });
    
    if (response.status === 200) {
      if (user) {

        user.folder.push(response.data.create._id)

        var responseUser = await updateObjectAPI(token, "user", {user}).catch((e) => {
          console.log(e)
          return e.response
        });

        if (responseUser.status === 200) {
          dispatch(addFolder(response.data.create))
          dispatch(setPage("folder"));
          dispatch(setFolder(response.data.create));
          dispatch(getPassword(token, response.data.create._id));
        }
      }
    }
  }

export const getPassword =
  (
    token: string,
    folder: string | undefined
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  async (dispatch: any) => {
    const response: any = await getPasswordAPI(token, folder, null).catch((e) =>{
      console.log(e)
      return e.response
    });
    
    if (response.status === 200) {
      const passwords: Password[] = response.data.password;
      dispatch(setPassword(passwords));
    }
  };

export const addPassword =
  (
    token: string,
    password: any,
    folder: string
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  async (dispatch: any) => {
    var response: any = await addObjectAPI(token, "password", { password }).catch((e) =>{
      console.log(e)
      return e.response
    });

    if (response.status === 200) {
      response = await getPasswordAPI(token, folder, null).catch((e) =>{
        console.log(e)
        return e.response
      });
      
      if (response.status === 200) {
        const passwords: Password[] = response.data.password;
        dispatch(setPassword(passwords));
      }
    }
  };

export const removePassword = 
  (
    token: string,
    password?: string,
    folder?: string
  ): ThunkAction<void, Store, unknown, Action<string>> => 
  async (dispatch: any) => {
    var response: any = await removeObjectAPI(token, "password", { password }).catch((e) => {
      console.log(e)
      return e.response
    });

    if (response.status === 200) {
      dispatch(getPassword(token, folder))
    }
  }

export const getRoles =
  (
    token: string,
    roles: string[]
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  async (dispatch: any) => {
    var listRole: Role[] = [];

    if (roles.length <= 0) {
      return;
    }

    const response: any = await getRoleAPI(token, roles, null).catch((e) =>{
      console.log(e)
      return e.response
    });

    if (response.status === 200) {

      listRole = response.data.role;

      dispatch(setRoles(listRole));

      listRole.forEach((role: Role) => {
        role.folder.forEach((folder: string) => {
          getFolderAPI(token, folder, undefined, undefined).then((response) => {
            dispatch(addFolder(response.data.folder[0]));
          });
        });
      });
    }
  };

export const getUsersAdmin =
  (
    token: string,
    company: string
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  async (dispatch: any) => {
    const response: any = await getObjectAPI(token, "user", { company }).catch((e) =>{
      console.log(e)
      return e.response
    });
    
    if (response.status === 200) {
      const users: User[] = response.data.user;
      dispatch(setUsers(users));
    }
  };

export const addUserAdmin =
  (
    token: string,
    user: User
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  async (dispatch: any) => {
    const response: any = await addObjectAPI(token, "user", {
      user: user,
    }).catch((e) =>{
      console.log(e)
      return e.response
    });

    if (response.status === 200) {
      var responseUsers = await getUserAPI(token, undefined, user.company).catch((e) =>{
        console.log(e)
        return e.response
      });

      if (responseUsers.status === 200) {
        const users: User[] = responseUsers.data.user;
        dispatch(setUsers(users));
      }
    }
  };

export const removeUserAdmin =
  (
    token: string,
    id: string,
    company: string
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  async (dispatch: any) => {
    const response: any = await removeObjectAPI(token, "user", { user: id }).catch((e) => {
      console.log(e)
      return e.response
    });

    if (response.status === 200) {
      var responseUsers = await getUserAPI(token, undefined, company).catch((e) =>{
        console.log(e)
        return e.response
      });
      
      if (responseUsers.status === 200) {
        const users: User[] = responseUsers.data.user;
        dispatch(setUsers(users));
      }
    }
  };


  export const updateUserAdmin =
  (
    token: string,
    user: User,
    company: string,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  async (dispatch: any) => {
    const response: any = await updateObjectAPI(token, "user", {user: user}).catch((e) => {
      console.log(e)
      return e.response
    });

    if (response.status === 200) {
      var responseUsers = await getUserAPI(token, undefined, company).catch((e) =>{
        console.log(e)
        return e.response
      });
      
      if (responseUsers.status === 200) {
        const users: User[] = responseUsers.data.user;
        dispatch(setUsers(users));
      }
    }
  };

export const getFoldersAdmin =
  (
    token: string,
    company: string
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  async (dispatch: any) => {
    const response: any = await getObjectAPI(token, "folder", {
      company,
    }).catch((e) =>{
      console.log(e)
      return e.response
    });
    
    if (response.status === 200) {
      const folders: Folder[] = response.data.folder;
      dispatch(setFoldersAdmin(folders));
    }
  };

export const addFolderAdmin =
  (
    token: string,
    folder: Folder
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  async (dispatch: any) => {
    const response: any = await addObjectAPI(token, "folder", { folder }).catch((e) =>{
      console.log(e)
      return e.response
    });

    if (response.status === 200) {
      var responseFolders = await getFolderAPI(token, null, folder.company, undefined).catch((e) =>{
        console.log(e)
        return e.response
      });
      
      if (responseFolders.status === 200) {
        const folders: Folder[] = responseFolders.data.folder;
        dispatch(setFoldersAdmin(folders));
      }
    }
  };

  export const updateFolderAdmin =
  (
    token: string,
    folder: Folder,
    company: string,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  async (dispatch: any) => {
    const response: any = await updateObjectAPI(token, "folder", {folder: folder}).catch((e) => {
      console.log(e)
      return e.response
    });

    if (response.status === 200) {
      var responseFolders = await getFolderAPI(token, null, company, undefined).catch((e) =>{
        console.log(e)
        return e.response
      });
      
      if (responseFolders.status === 200) {
        const folders: Folder[] = responseFolders.data.folder;
        dispatch(setFoldersAdmin(folders));
      }
    }
  };

export const removeFolderAdmin =
  (
    token: string,
    id: string,
    company: string
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  async (dispatch: any) => {
    const response: any = await removeObjectAPI(token, "folder", { folder: id }).catch((e) =>{
      console.log(e)
      return e.response
    });

    if (response.status === 200) {
      var responseFolders = await getFolderAPI(token, null, company, undefined).catch((e) =>{
        console.log(e)
        return e.response
      });
      
      if (responseFolders.status === 200) {
        const folders: Folder[] = responseFolders.data.folder;
        dispatch(setFoldersAdmin(folders));
      }
    }
  };

export const getRolesAdmin =
  (
    token: string,
    company: string
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  async (dispatch: any) => {
    const response: any = await getObjectAPI(token, "role", {
      company,
    }).catch((e) =>{
      console.log(e)
      return e.response
    });
    
    if (response.status === 200) {
      const roles: Role[] = response.data.role;
      dispatch(setRolesAdmin(roles));
    }
  };

export const addRoleAdmin =
  (
    token: string,
    role: Role
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  async (dispatch: any) => {
    const response: any = await addObjectAPI(token, "role", { role }).catch((e) =>{
      console.log(e)
      return e.response
    });

    if (response.status === 200) {
      var responseRoles = await getRoleAPI(token, null, role.company).catch((e) =>{
        console.log(e)
        return e.response
      });
      
      if (responseRoles.status === 200) {
        const roles: Role[] = responseRoles.data.role;
        dispatch(setRolesAdmin(roles));
      }
    }
  };

  export const updateRoleAdmin =
  (
    token: string,
    role: Role,
    company: string,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  async (dispatch: any) => {
    const response: any = await updateObjectAPI(token, "role", {role: role}).catch((e) => {
      console.log(e)
      return e.response
    });

    if (response.status === 200) {
      var responseRoles = await getRoleAPI(token, null, company).catch((e) =>{
        console.log(e)
        return e.response
      });
      
      if (responseRoles.status === 200) {
        const roles: Role[] = responseRoles.data.role;
        dispatch(setRolesAdmin(roles));
      }
    }
  };

export const removeRoleAdmin =
  (
    token: string,
    id: string,
    company: string
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  async (dispatch: any) => {
    const response: any = await removeObjectAPI(token, "role", { role: id }).catch((e) =>{
      console.log(e)
      return e.response
    });

    if (response.status === 200) {
      var responseRoles = await getRoleAPI(token, null, company).catch((e) =>{
        console.log(e)
        return e.response
      });
      
      if (responseRoles.status === 200) {
        const roles: Role[] = responseRoles.data.role;
        dispatch(setRolesAdmin(roles));
      }
    }
  };

export const getPasswordsAdmin =
  (
    token: string,
    company: string
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  async (dispatch: any) => {
    const response: any = await getObjectAPI(token, "password", {
      company,
    }).catch((e) =>{
      console.log(e)
      return e.response
    });
    
    if (response.status === 200) {
      const passwords: Password[] = response.data.password;
      dispatch(setPasswordsAdmin(passwords));
    }
  };

export const addPasswordAdmin =
  (
    token: string,
    password: Password,
    company: string
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  async (dispatch: any) => {
    const response: any = await addObjectAPI(token, "password", {
      password,
    }).catch((e) =>{
      console.log(e)
      return e.response
    });

    if (response.status === 200) {
      var responsePasswords = await getPasswordAPI(token, undefined, company).catch((e) =>{
        console.log(e)
        return e.response
      });

      if (responsePasswords.status === 200) {
        const passwords: Password[] = responsePasswords.data.password;
        dispatch(setPasswordsAdmin(passwords));
      }
    }
  };

  export const updatePasswordAdmin =
  (
    token: string,
    password: Password,
    company?: string,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  async (dispatch: any) => {
    const response: any = await updateObjectAPI(token, "password", {password: password}).catch((e) => {
      console.log(e)
      return e.response
    });

    if (response.status === 200) {
      var responsePasswords = await getPasswordAPI(token, undefined, company).catch((e) =>{
        console.log(e)
        return e.response
      });
      
      if (responsePasswords.status === 200) {
        const passwords: Password[] = responsePasswords.data.password;
        dispatch(setPasswordsAdmin(passwords));
      }
    }
  };

export const removePasswordAdmin =
  (
    token: string,
    id: string,
    company?: string
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  async (dispatch: any) => {
    const response: any = await removeObjectAPI(token, "password", { password: id }).catch((e) =>{
      console.log(e)
      return e.response
    });

    if (response.status === 200) {
      var responsePasswords = await getPasswordAPI(token, undefined, company).catch((e) =>{
        console.log(e)
        return e.response
      });

      if (responsePasswords.status === 200) {
        const passwords: Password[] = responsePasswords.data.password;
        dispatch(setPasswordsAdmin(passwords));
      }
    }
  };

export const getCompaniesAdmin =
  (
    token: string,
    company: string
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  async (dispatch: any) => {
    const response: any = await getObjectAPI(token, "company", {
      company,
    }).catch((e) =>{
      console.log(e)
      return e.response
    });
    
    if (response.status === 200) {
      const companies: Company = response.data.company;
      dispatch(setCompaniesAdmin([companies]));
    }
  };

export const addCompanyAdmin =
  (
    token: string,
    company: Company
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  async (dispatch: any) => {
    const response: any = await addObjectAPI(token, "company", {
      company,
    }).catch((e) =>{
      console.log(e)
      return e.response
    });

    if (response.status === 200) {
      var responseCompanies = await getCompanyAPI(token, company._id).catch((e) =>{
        console.log(e)
        return e.response
      });
      
      if (responseCompanies.status === 200) {
        const companies: Company = responseCompanies.data.company;
        dispatch(setCompaniesAdmin([companies]));
      }
    }
  };

  export const updateCompanyAdmin =
  (
    token: string,
    company: Company,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  async (dispatch: any) => {
    const response: any = await updateObjectAPI(token, "company", {company: company}).catch((e) => {
      console.log(e)
      return e.response
    });

    if (response.status === 200) {
      var responseCompanies = await getCompanyAPI(token, company?._id).catch((e) =>{
        console.log(e)
        return e.response
      });
      
      if (responseCompanies.status === 200) {
        const company: Company = responseCompanies.data.company;
        dispatch(setCompaniesAdmin([company]));
      }
    }
  };

export const removeCompanyAdmin =
  (
    token: string,
    id: string,
    company: string
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  async (dispatch: any) => {
    const response: any = await removeObjectAPI(token, "company", { company: id }).catch((e) =>{
      console.log(e)
      return e.response
    });

    if (response.status === 200) {
      var responseCompany = await getCompanyAPI(token, company).catch((e) =>{
        console.log(e)
        return e.response
      });
      
      if (responseCompany.status === 200) {
        const companies: Company = responseCompany.data.company;
        dispatch(setCompaniesAdmin([companies]));
      }
    }
  };
