import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Center,
  Spinner,
  Button,
  HStack,
  Tag,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Text,
  Icon
} from "@chakra-ui/react";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { MdRefresh } from 'react-icons/md';

import { Access, User } from "../../../models/models";
import { getAloneAPI, updateObjectAPI } from "../../../utils/API";
import { getUsersAdmin } from "../../../store/actions";
import { getFromStorage } from "../../../utils/utils";
import { Colors } from "../../../style/color";
import theme from "../../../style/theme";
import { select_style } from "../../../style/select";

const animatedComponents = makeAnimated();

export default function ModalContentUser({ onClose, isOpen, company }: any) {
  const [choices, setChoices] = React.useState<any[]>([]);
  const [options, setOptions] = React.useState<any[]>([]);
  const [users, setUsers] = React.useState<User[]>([]);

  const dispatch = useDispatch();

  useEffect(() => {
    getOptions();
  }, []);

  async function getOptions() {
    var response = await getAloneAPI(getFromStorage("token"));

    if (response.status === 200) {
      var list: any = [];
      response.data.user.forEach((user: User) => {
        list.push({ label: user.name, value: user });
      });
      setOptions(list);
    }
  }

  async function addUserToCompany() {
    if (choices.length > 0) {
      for (var i in choices) {
        choices[i].value.company = company[0]._id;
        choices[i].value.access = Access.USER;
        var response = await updateObjectAPI(getFromStorage("token"), "user", {
          user: choices[i].value,
        });
      }
      dispatch(getUsersAdmin(getFromStorage("token"), company[0]._id));
    }
    onClose();
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} size={"5xl"}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader fontSize={"18px"}>
          <Text>Add user to company <Button onClick={() => getOptions()}>Refresh <Icon as={MdRefresh} ml={"5px"} size={"lg"} /></Button></Text>
        </ModalHeader>
        <ModalCloseButton size={"lg"} m={"10px"} />
        <ModalBody m={"10px 5px 20px 5px"}>
          <Text fontSize={"16px"} mb={"7px"}>
            Select users to add
          </Text>
          <Select
            styles={select_style}
            closeMenuOnSelect={true}
            components={animatedComponents}
            isMulti
            options={options}
            value={choices}
            // styles={colourStyles}
            onChange={(choice: any) => setChoices(choice)}
          />
        </ModalBody>
        <ModalFooter>
          <Button
            mr={3}
            onClick={onClose}
            fontSize={"16px"}
            size={"lg"}
            variant="ghost"
          >
            Close
          </Button>
          <Button
            colorScheme="blue"
            fontSize={"16px"}
            size={"lg"}
            onClick={() => addUserToCompany()}
          >
            Add
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
