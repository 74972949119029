import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route, Link, useNavigate } from "react-router-dom";
import { List } from "@mui/material";
import {
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Box,
  Center,
  Spacer,
  Text,
  Flex,
  Button,
  Spinner,
  Select,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  FormHelperText,
  useColorModeValue,
  FormErrorMessage
} from "@chakra-ui/react";
import { ArrowBackIcon, ArrowForwardIcon } from "@chakra-ui/icons";
import { useDispatch, useSelector } from "react-redux";

import {
    verifyEmail,
    verifyName,
  } from "../utils/utils";
import { toast } from "react-toastify";
import { contactEmail } from "../utils/API";
import { Store, User } from "../models/models";
import { Colors } from "../style/color";

export default function ContactForm() {

    const [email, setEmail] = React.useState<string>("");
    const [name, setName] = React.useState<string>("");
    const [subject, setSubject] = React.useState<string>("");
    const [message, setMessage] = React.useState<string>("");
    const [loading, setLoading] = React.useState<boolean>(false);
    
    const user: User | undefined = useSelector((state: Store) => state.user);

    const bg = useColorModeValue(Colors.bgWhite, Colors.bgDark);

    useEffect(() => {
        if (user) {
            setName(user.name);
            setEmail(user.email);
        } else if (name) {
            setName("");
        } else if (email) {
            setEmail("");
        }
    }, [user])

    async function sendMessage() {
        if (!name) {
            toast.error("Invalid name", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
                });
            return;
        }
        if (!email && !verifyEmail(email)) {
            toast.error("Invalid email", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
                });
            return;
        }
        if (!subject) {
            toast.error("Invalid subject", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
                });
            return;
        }
        if (!message) {
            toast.error("Invalid message", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
                });
            return;
        }

        setLoading(true);

        var response = await contactEmail(email, name, subject, message).catch(e => {return e.response})

        if (response.status === 200){
            toast.success(response.data.text, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
                });
        } else {
            toast.error(response.data.text, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
                });
        }

        setLoading(false);
    }

    return (
        <Box w={"100%"} h={"100vh"} position={"relative"}>
            <Box h="25vh" w="100%" p="4% 10%">
                <Text fontSize="80px" className="titleFolder">
                    Contact
                </Text>
                <Text>Fill in this form to contact us</Text>
            </Box>
            <Box h={"75vh"} w={"100%"} pt={"5vh"}>
                <Box w={"60%"} bg={bg} borderRadius={"8px"} p={"5vh 5%"} m={"auto"}>
                    <FormControl>
                        <Flex flexDirection={"row"}>
                            <Box flex={"1"} mr={"10px"}>
                                <FormLabel htmlFor="name"  fontSize={"15px"}>Name</FormLabel>
                                <Input 
                                    type='text' 
                                    size={"lg"}
                                    fontSize={"15px"}
                                    value={name} 
                                    onChange={(e) => setName(e.currentTarget.value)} 
                                    />
                            </Box>
                            <Box flex={"1"} ml={"10px"}>
                                <FormLabel htmlFor="email"  fontSize={"15px"}>Email address</FormLabel>
                                <Input 
                                    type='email' 
                                    size={"lg"}
                                    fontSize={"15px"}
                                    value={email} 
                                    onChange={(e) => setEmail(e.currentTarget.value)} 
                                    />
                                <FormHelperText fontSize={"10px"}>
                                    This email will be used to reply to you.
                                </FormHelperText>
                            </Box>
                        </Flex>
                        <FormLabel htmlFor="subject" fontSize={"15px"}>Subject</FormLabel>
                        <Select
                        id="subject"
                        value={subject}
                        size={"lg"}
                        onChange={e => setSubject(e.target.value)}
                        fontSize={"15px"}
                        >
                            <option value="">Select a subject</option>
                            <option value="support">Support</option>
                            <option value="sales">Sales</option>
                            <option value="feedback">Feedback</option>
                        </Select>
                        <br></br>
                        <FormLabel htmlFor="message" fontSize={"15px"}>Message</FormLabel>
                        <Textarea
                            size={"lg"}
                            id="message"
                            h={"15vh"}
                            value={message}
                            onChange={e => setMessage(e.target.value)}
                            fontSize={"15px"}
                            />
                        <Flex flexDirection={"row-reverse"}>
                            <Button
                                mt={4}
                                size={"lg"}
                                fontSize={"15px"}
                                colorScheme='teal'
                                isLoading={loading}
                                onClick={sendMessage}
                            >
                                Submit
                            </Button>
                        </Flex>
                    </FormControl>
                </Box>
            </Box>
        </Box>
    );
}
