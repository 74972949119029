export const Colors = {
    textWhite: "#F1F1F1",
    textBlack: "#1F1F1F",
    textGray: "#A0AEC0",
    textBlue: "#1C2942",
    bgBlack: "#1F1F1F",
    bgBlackMain: "#3d3d3d",
    bgDark: "#17191d",
    bgDarkMain: "#22252b",
    bgGray: "#ebf0f5",
    bgWhite: "#FFFFFF",
    bgWhite2: "#F1F1F1",
    bgCanard: "#3B556D",
    toastWhite: "light",
    toastDark: "dark",
}