import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Center,
  Spinner,
  Button,
  HStack,
  Tag,
  Input,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Text,
  Box,
  useToast,
  Flex,
  Switch,
  useColorModeValue
} from "@chakra-ui/react";
import { CirclePicker } from 'react-color';
import { toast } from 'react-toastify';

import ModalRemove from "./admin/modals/ModalRemove";
import { getFromStorage, unCryptData } from "../utils/utils";
import { removeFolderPerso, setPage } from "../store/actions";
import { Colors } from "../style/color";
import { Folder, Store } from "../models/models";

export function ManageFolderModal({ onClose, isOpen, onOpen, action, user, folder }: any) {
    const [name, setName] = React.useState<string>("");
    const [color, setColor] = React.useState<string>("");
    
    const { isOpen: isOpenDeleteFolder, onOpen: onOpenDeleteFolder, onClose: onCloseDeleteFolder } = useDisclosure();

    const dispatch = useDispatch();

    useEffect(() => {
        if (folder) {
            setName(unCryptData(folder.name));
            setColor(unCryptData(folder.color));
        }
    }, [folder])

    function removeFolder() {
        if (folder) {
            dispatch(removeFolderPerso(getFromStorage("token"), folder, user));
            dispatch(setPage("/main"));
            onCloseDeleteFolder();
            onClose();
            toast.info(unCryptData(folder.name) + " folder deleted", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
            });
      }
    }

    if (!folder && isOpen) {
        return (
            <Center w={"100%"} h={"100%"}>
              <Spinner size={"xl"} />
            </Center>
        )
    }   

    return (
        <Modal isOpen={isOpen} onClose={onClose} size={"6xl"}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader fontSize={"18px"}>
                Manage folder {unCryptData(folder?.name)}
                </ModalHeader>
                <ModalCloseButton size={"lg"} m={"10px"} />
                <ModalBody p={"20px 20px"} overflow={"hidden"}>
                    <Flex>
                        <Box pt={"3vh"} pb={"5vh"}>
                            <Box mt={"10px"} mx={"10px"}>
                                <Text fontSize={"16px"}>Name :</Text>
                                <Input
                                    placeholder="Enter name ..."
                                    size="lg"
                                    type="text"
                                    fontSize={"14px"}
                                    value={name}
                                    onKeyDown={(e) => e.key === 'Enter' && action(folder, name, color)}
                                    onChange={(evt) => setName(evt.currentTarget.value)}
                                />
                            </Box>
                            <Box mt={"8vh"} mx={"10px"}>
                                <Text fontSize={"16px"}>Color :</Text>
                                <Box mt={"20px"}>    
                                    <CirclePicker
                                        // colors={["#55efc4", "#81ecec", "#74b9ff", "#a29bfe", "#dfe6e9", "#00b894", "#00cec9", "#0984e3", "#6c5ce7", "#b2bec3", "#ffeaa7", "#fab1a0", "#ff7675", "#fd79a8", "#636e72", "#fdcb6e", "#e17055", "#d63031", "#e84393", "#2d3436"]}
                                        colors={["#f44336", "#e91e63", "#9c27b0", "#673ab7", "#3f51b5", "#2196f3", "#03a9f4", "#00bcd4", "#009688", "#4caf50", "#8bc34a", "#cddc39", "#ffeb3b", "#ffc107", "#ff9800", "#ff5722", "#795548", "#607d8b", "#3B556D"]} 
                                        color={color} 
                                        onChangeComplete={(color: any) => {
                                            setColor(color.hex);
                                        }} />
                                </Box>
                            </Box>
                        </Box>
                        <Box w="100%" position={"relative"}>
                            <Text fontSize={"65px"} w={"85%"} top={"125px"} left={65} pl={"5%"} className="titleFolder" position={"absolute"} zIndex={100}>
                                {name}
                            </Text>
                            <Box w={200} h={200} top={0} left={"20%"} position={"absolute"} zIndex={0}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="150px" height="150px" viewBox="0 0 48 48"><path fill={color+"99"} d="M38,12H22l-4-4H8c-2.2,0-4,1.8-4,4v24c0,2.2,1.8,4,4,4h31c1.7,0,3-1.3,3-3V16C42,13.8,40.2,12,38,12z"/><path fill={color} d="M42.2,18H15.3c-1.9,0-3.6,1.4-3.9,3.3L8,40h31.7c1.9,0,3.6-1.4,3.9-3.3l2.5-14C46.6,20.3,44.7,18,42.2,18z"/></svg>
                            </Box>
                        </Box>
                    </Flex>
                </ModalBody>
                <ModalFooter>
                    <Flex w={"100%"} justify={"space-between"}>
                        <Button 
                            color={"red.500"} 
                            bg={"none"} 
                            px="2%" 
                            size="lg" 
                            fontSize="16px" 
                            zIndex={100} 
                            onClick={onOpenDeleteFolder}
                            >
                            Delete folder
                        </Button>   
                        <Flex>
                            <Button
                                mr={3}
                                onClick={onClose}
                                fontSize={"16px"}
                                size={"lg"}
                                variant="ghost"
                            >
                                Close
                            </Button>
                            <Button
                                colorScheme="blue"
                                fontSize={"16px"}
                                size={"lg"}
                                onClick={() => action(folder, name, color)}
                            >
                                Save
                            </Button>
                        </Flex>
                    </Flex>
                </ModalFooter>
            </ModalContent>
            <ModalRemove onClose={onCloseDeleteFolder} isOpen={isOpenDeleteFolder} folder={folder} action={removeFolder} title={"Do you want to remove the folder ?"} text={"Deleting the folder will delete all the passwords associated with it"} />
        </Modal>
    );
}
