import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import {
  Flex,
  Spacer,
  Center,
  Text,
  Square,
  Box,
  Button,
} from "@chakra-ui/react";

export default function NotFound() {

  return (
    <Box w={"100%"} h={"100%"}>
      <Box h="25vh" w="100%" p="10% 10%" position={"relative"}>
        <Text fontSize="80px" className="titleFolder">
          Error 404
        </Text>
        <Text>Page not found</Text>
        <Box mt={"5vh"}>
            <Link to={"/"} style={{ textDecoration: "none" }}>
                <Button
                    m={"auto"}
                    colorScheme="teal"
                    type="submit"
                    size={"lg"}
                    fontSize={"14px"}
                    >
                    Back to home
                </Button>
            </Link>
        </Box>
      </Box>
    </Box>
  );
}
