import React, { useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import {
  Flex,
  Spacer,
  Center,
  Text,
  Square,
  Box,
  useColorModeValue,
  Button,
  useDisclosure,
} from "@chakra-ui/react";
import { CloseIcon } from "@chakra-ui/icons"

import { Folder, Store, User } from "../models/models";
import ChangePassword from "./user/ChangePassword";
import { setPage } from "../store/actions";
import UserInfo from "./user/UserInfo";
import ModalRemove from "./admin/modals/ModalRemove";
import { clearStorage, getFromStorage } from "../utils/utils";
import { removeAccount as removeAccountAPI } from "../utils/API";
import ModalEdit from "./admin/modals/ModalEdit";

export default function UserPage() {
    const user: User | undefined = useSelector((state: Store) => state.user);
    const folder: Folder | undefined = useSelector((state: Store) => state.folder);
    
    const { isOpen: isOpenDeleteAccount, onOpen: onOpenDeleteAccount, onClose: onCloseDeleteAccount } = useDisclosure();

    const dispatch = useDispatch();
    const navigate = useNavigate();

    async function removeAccount() {
        if (user) {
            var response = await removeAccountAPI(getFromStorage('token'), user).catch((e) => {return e.response});
            console.log(response);
            if (response.status === 200) {
                clearStorage()
                navigate("/", {replace: true})
            } 
        }
    }

    return (
        <Box w={"100%"} h={"100%"} overflow={"hidden"} position={"relative"}>
            <Box 
                zIndex={"1"}
                position="absolute" 
                top={"20px"} 
                right={"20px"} 
                style={{cursor: "pointer"}} 
                onClick={() => {
                    if (folder) {
                        dispatch(setPage("folder"));
                    } else {
                        dispatch(setPage("main"));
                    }
                }}>
                <CloseIcon />
            </Box>
            <Box h="70vh" w="100%">
                <Box m="5% 8%">
                    <Text fontSize="80px" className="titleFolder" mb={"0px"}>
                        {user?.name}
                    </Text>
                    <Text fontSize={"10px"} color={"gray.500"} mt={"0px"}>
                        Member since { 
                            user?.created_at ? 
                                (new Date(user?.created_at)).toLocaleDateString('en-US', {
                                    day: 'numeric',
                                    month: 'long',
                                    year: 'numeric'
                                    }) 
                                    : 
                                    ""
                            }
                    </Text>
                </Box>
                <Box h="60vh" w="40%" position={"absolute"} top={"10vh"} right={"5%"}>
                    <ChangePassword />
                </Box>
                <Box h="40vh" w="40%" position={"absolute"} top={"35vh"} left={"5%"}>
                    <UserInfo />
                </Box>
                <Box position={"absolute"} bottom={"20px"} right={"20px"}>
                    <Button 
                        color={"red.500"} 
                        bg={"none"} 
                        px="20px" 
                        size="lg" 
                        fontSize="16px" 
                        zIndex={100} 
                        onClick={onOpenDeleteAccount}
                        >
                        Delete Account
                    </Button> 
                </Box>
            </Box>
            <ModalRemove onClose={onCloseDeleteAccount} isOpen={isOpenDeleteAccount} action={removeAccount} title={"Do you want to remove your account ?"} text={"Once deleted, it will not be recoverable and will be deleted forever"} />
        </Box>
    )
}