import axios from "axios";
import secureLocalStorage from "react-secure-storage"
import { User } from "../models/models";
var CryptoJS = require("crypto-js");

export function cryptData(value: any, salt?: any) {
  if (!salt) {
    salt = getFromStorage("password_maitre");
  }
  try {
    var ciphertext = CryptoJS.AES.encrypt(
      JSON.stringify(value),
      salt
    ).toString();
  
    return ciphertext;
  } catch (error) {
    return ""
  }
}

export function unCryptData(value: any, salt?: any) {
  if (!salt) {
    salt = getFromStorage("password_maitre");
  }
  if (salt && value) {
    try {
      var bytes = CryptoJS.AES.decrypt(value, salt);
      var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      return decryptedData;
    } catch (error) {
      return ""
    }
  } else {
    return "";
  }
}

export function subString(str: string): string {
  return str.length > 25 ? str.substring(0, 25) + "..." : str
}

export function clearStorage() {
  secureLocalStorage.removeItem("token");
  secureLocalStorage.removeItem("user");
  secureLocalStorage.removeItem("password_maitre");
}

export function hash(password_maitre: string) {
  // console.log(CryptoJS.SHA256(password_maitre).toString());
  return CryptoJS.SHA256(password_maitre).toString();
}

export function getFromStorage(item: string) {
  var element = secureLocalStorage.getItem(item)
  if (element && typeof element === 'string') {
    return element;
  } else {
    return "";
  }
}

export function cryptPassword(password: any, user: User) {
  delete password["company"];
  delete password["_id"];
  password.name = cryptData(password.name);
  password.username = cryptData(password.username);
  password.password = cryptData(password.password);
  password.url = cryptData(password.url);
  password.created_by = user._id
  return password;
}

export function cryptFolder(folder: any, user: User) {
  delete folder["_id"];
  folder.name = cryptData(folder.name);
  folder.color = cryptData(folder.color ? folder.color : "#2196f3");
  folder.created_by = user._id
  return folder;
}

export function cryptRole(role: any, user: User) {
  delete role["_id"];
  role.name = cryptData(role.name);
  role.color = cryptData(role.color ? role.color : "#2196f3");
  role.created_by = user._id
  return role;
}

export function verifyName(name: string) {
  if (name.includes(" ")) {
    return false;
  } else {
    return true;
  }
}

export function verifyPassword(password: string) {
  if (password.includes(" ") || password.length < 8 || !/\d/.test(password) || !/[A-Z]/.test(password)) {
    return false;
  } else {
    return true;
  }
}

export function verifyEmail(email: string): boolean {
  const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  if (emailRegex.test(email)) {
    return true;
  } else {
    return false;
  }
}

export function escapeInput(input: string) {
  return String(input)
    .replace(/&/g, '&amp;')
    .replace(/"/g, '&quot;')
    .replace(/'/g, '&#39;')
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;');
}

export async function getIcon(url: string) {
  return await axios.get("https://s2.googleusercontent.com/s2/favicons?domain=" + url)
}
