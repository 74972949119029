import React from "react";
import { createStore, applyMiddleware } from "redux";
import { configureStore } from "@reduxjs/toolkit";
import thunk from "redux-thunk";

import { Folder, Store, User } from "../models/models";
import {
  ActionTypes,
  ADD_FOLDER,
  CREATE_FOLDER,
  DELETE_FOLDER,
  SET_COMPANY,
  SET_FOLDER,
  SET_FOLDERS,
  SET_PAGE,
  SET_PASSWORD,
  SET_ROLES,
  SET_USER,
  SET_USERS_ADMIN,
  SET_FOLDERS_ADMIN,
  SET_ROLES_ADMIN,
  SET_PASSWORDS_ADMIN,
  SET_COMPANIES_ADMIN,
  UPDATE_FOLDER,
  SET_FOLDERS_PERSO,
  ADD_FOLDER_PERSO,
  UPDATE_FOLDER_PERSO,
} from "./actions";
import { unCryptData } from "../utils/utils";

// Redux implementation
function globalReducer(
  state: Store = {
    user: undefined,
    folders: [],
    foldersPerso: [],
    folder: undefined,
    roles: [],
    passwords: [],
    company: undefined,
    page: "main",
    usersAdmin: [],
    foldersAdmin: [],
    rolesAdmin: [],
    passwordsAdmin: [],
    companiesAdmin: [],
  },
  action: ActionTypes
) {
  switch (action.type) {
    case SET_FOLDERS:
      return {
        ...state,
        folders: action.payload,
      };
    case SET_FOLDERS_PERSO:
      return {
        ...state,
        foldersPerso: action.payload,
      };
    case ADD_FOLDER_PERSO:
      return {
        ...state,
        foldersPerso: addFolder(state.foldersPerso, action.payload)
      }
    case UPDATE_FOLDER_PERSO:
      return {
        ...state,
        foldersPerso: updateFolder(state.foldersPerso, action.payload)
      }
    case DELETE_FOLDER:
      return {
        ...state,
        foldersPerso: deleteFolder(state.user, state.foldersPerso, action.payload),
      };
    case UPDATE_FOLDER:
      return {
        ...state,
        folders: updateFolder(state.folders, action.payload),
      };
    case CREATE_FOLDER:
      return {
        ...state,
        folders: createFolder(state.user, state.folders, action.payload),
      };
    case SET_USER:
      return {
        ...state,
        user: action.payload,
      };
    case SET_PASSWORD:
      return {
        ...state,
        passwords: action.payload,
      };
    case SET_FOLDER:
      return {
        ...state,
        folder: action.payload,
      };
    case ADD_FOLDER:
      return {
        ...state,
        folders: addFolder(state.folders, action.payload),
      };
    case SET_COMPANY:
      return {
        ...state,
        company: action.payload,
      };
    case SET_ROLES:
      return {
        ...state,
        roles: action.payload,
      };
    case SET_PAGE:
      return {
        ...state,
        page: action.payload,
      };
    case SET_USERS_ADMIN:
      return {
        ...state,
        usersAdmin: action.payload,
      };
    case SET_FOLDERS_ADMIN:
      return {
        ...state,
        foldersAdmin: action.payload,
      };
    case SET_ROLES_ADMIN:
      return {
        ...state,
        rolesAdmin: action.payload,
      };
    case SET_PASSWORDS_ADMIN:
      return {
        ...state,
        passwordsAdmin: action.payload,
      };
    case SET_COMPANIES_ADMIN:
      return {
        ...state,
        companiesAdmin: action.payload,
      };
    default:
      return state;
  }
}

const addFolder = (folders: Folder[], folder: Folder): Folder[] => {
  return folders.some((e) => JSON.stringify(e) === JSON.stringify(folder))
    ? folders
    : [...folders, folder];
};

const deleteFolder = (
  user: User | undefined,
  folders: Folder[],
  folder: Folder
): Folder[] => {
  return folders.filter(folderArray => { return folderArray._id !== folder._id; });
};

const createFolder = (
  user: User | undefined,
  folders: Folder[],
  folder: Folder
): Folder[] => {
  return folders;
};

const updateFolder = (
  folders: Folder[],
  folder: Folder
): Folder[] => {
  console.log("Passe");
  console.log(unCryptData(folder.name));
  var objIndex = folders.findIndex(((obj: any) => obj._id === folder._id));

  console.log("Before ", unCryptData(folders[objIndex].name));
  folders[objIndex] = folder;
  console.log("After ", unCryptData(folders[objIndex].name));

  return folders;
};

//const store = configureStore({ reducer: globalReducer, middleware: [thunk] });

const store = createStore(globalReducer, applyMiddleware(thunk));

export default store;
