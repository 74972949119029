import React, { useEffect, useRef, createRef } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Button,
  FormControl,
  FormLabel,
  Input,
  Switch,
  Center,
  Box
} from "@chakra-ui/react";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { useDispatch, useSelector } from "react-redux";
import { CirclePicker } from 'react-color';

import {
  cryptFolder,
  cryptPassword,
  cryptRole,
  getFromStorage,
  unCryptData,
} from "../../../utils/utils";
import {
  addPasswordAdmin,
  addCompanyAdmin,
  addFolderAdmin,
  addRoleAdmin,
  addUserAdmin,
} from "../../../store/actions";
import { Access } from "../../../models/models";
import theme from "../../../style/theme";
import { select_style } from "../../../style/select";

const animatedComponents = makeAnimated();

export default function DynamicModal({
  onModalClose,
  onSave,
  columns,
  validateState,
  ignoreEditable,
  folders,
  roles,
  company,
  object,
  user
}: any) {
  const [color, setColor] = React.useState<string>("#2196f3");
  const [open, setOpen] = React.useState<boolean>(true);

  const dispatch = useDispatch();

  async function handleSaveBtnClick() {
    var newRow: any = {};

    columns.forEach((element: any, index: number) => {
      if (element.field === "folder" || element.field === "role") {
        if (element.value) {
          if (object === "Password") {
            newRow[element.field] = element.value.value;
          } else {
            var data: string[] = [];
            element.value.forEach((item: any) => {
              data.push(item.value);
            });
            newRow[element.field] = data;
          }
        } else {
          newRow[element.field] = [];
        }
      } else if (element.field === "access") {
        newRow[element.field] = element.value.value;
      } else if (element.value) {
        newRow[element.field] = element.value;
      } else {
        newRow[element.field] = "";
        return
      }
    });
    newRow["company"] = company[0]._id;
    delete newRow["action"];

    console.log(newRow);
    var token = getFromStorage("token")
    switch (object) {
      case "User":
        delete newRow["_id"];
        // function verify object creation
        dispatch(addUserAdmin(token, newRow));
        break;
      case "Password":
        newRow = cryptPassword(newRow, user);
        dispatch(addPasswordAdmin(token, newRow, company[0]._id));
        break;
      case "Role":
        newRow = cryptRole(newRow, user);
        dispatch(addRoleAdmin(token, newRow));
        break;
      case "Folder":
        newRow = cryptFolder(newRow, user);
        dispatch(addFolderAdmin(token, newRow));
        break;
      case "Company":
        newRow.created_by = user._id
        dispatch(addCompanyAdmin(token, newRow));
        break;

      default:
        break;
    }
    onModalClose();
    //onSave(newRow);
  }

  return (
    <Modal isOpen={open} onClose={onModalClose} size={"5xl"}>
      <ModalOverlay />
      <ModalContent>
          <ModalHeader fontSize={"18px"}>Create {object}</ModalHeader>
          <ModalCloseButton size={"lg"} m={"10px"} />
          <ModalBody m={"10px 5px 20px 5px"}>
          {
            columns.map((column: any, i: number) => {
              const { editable, format, field, name, hiddenOnInsert }: any = column;

              if (hiddenOnInsert) {
                // when you want same auto generate value
                // and not allow edit, for example ID field
                return null;
              }
              if (name === "Folder") {
                var options = folders.map((folder: any) => {
                  return { label: unCryptData(folder.name), value: folder._id };
                });
                return (
                  <div style={{ margin: "10px 20px" }}>
                    <label className="form-check-label">Folder</label>
                    <Select
                      styles={select_style}
                      closeMenuOnSelect={true}
                      components={animatedComponents}
                      isMulti={object !== "Password"}
                      options={options}
                      onChange={(choice: any) => (column.value = choice)}
                    />
                  </div>
                );
              }
              if (name === "Role") {
                var options = roles.map((role: any) => {
                  return { label: unCryptData(role.name), value: role._id };
                });
                return (
                  <div style={{ margin: "10px 20px" }}>
                    <label className="form-check-label">Role</label>
                    <Select
                      styles={select_style}
                      closeMenuOnSelect={true}
                      components={animatedComponents}
                      isMulti
                      options={options}
                      onChange={(choice: any) => (column.value = choice)}
                    />
                  </div>
                );
              }
              if (name === "Access") {
                var options: any = [];
                options.push({label: Access.ADMIN, value: Access.ADMIN})
                options.push({label: Access.OWNER, value: Access.OWNER})
                options.push({label: Access.USER, value: Access.USER})
                options.push({label: Access.GUEST, value: Access.GUEST})
                return (
                  <div style={{ margin: "10px 20px" }}>
                    <label className="form-check-label">Access</label>
                    <Select
                      styles={select_style}
                      closeMenuOnSelect={true}
                      components={animatedComponents}
                      options={options}
                    onChange={(choice: any) => (column.value = choice)}
                  />
                </div>
              );
            }
            if (name === "Color") {
              return (
                <Box style={{ margin: "10px 20px" }}>
                  <label className="form-check-label">Color :</label>
                  <Center>
                    <CirclePicker
                      // colors={["#55efc4", "#81ecec", "#74b9ff", "#a29bfe", "#dfe6e9", "#00b894", "#00cec9", "#0984e3", "#6c5ce7", "#b2bec3", "#ffeaa7", "#fab1a0", "#ff7675", "#fd79a8", "#636e72", "#fdcb6e", "#e17055", "#d63031", "#e84393", "#2d3436"]}
                      colors={["#f44336", "#e91e63", "#9c27b0", "#673ab7", "#3f51b5", "#2196f3", "#03a9f4", "#00bcd4", "#009688", "#4caf50", "#8bc34a", "#cddc39", "#ffeb3b", "#ffc107", "#ff9800", "#ff5722", "#795548", "#607d8b"]} 
                      color={color} 
                      onChangeComplete={(color: any) => {
                        setColor(color.hex);
                        column.value = color.hex;
                      }} />
                  </Center>
                </Box>
              )
            }
            return (
              <div
                className="form-group"
                key={field}
                style={{ margin: "10px 20px" }}
              >
                <div className="mb-3">
                  <label className="form-label">{name} : </label>
                  <Input
                    size="lg"
                    fontSize={"14px"}
                    autoFocus={true}
                    onKeyDown={(e) => e.key === 'Enter' && handleSaveBtnClick()}
                    type={name === "Password" ? "password" : "text"}
                    className="form-control"
                    id={field}
                    placeholder=""
                    value={column.value}
                    onChange={(evt) => (column.value = evt.currentTarget.value)}
                  />
                </div>
              </div>
            );
          })}
          </ModalBody>
          <ModalFooter>
          <Button
              mr={3}
              onClick={onModalClose}
              fontSize={"16px"}
              size={"lg"}
              variant="ghost"
          >
              Close
          </Button>
          <Button
              colorScheme="blue"
              fontSize={"16px"}
              size={"lg"}
              onClick={() => handleSaveBtnClick()}
          >
              Update
          </Button>
          </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
