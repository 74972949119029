import React from "react";
import { Center, Spinner } from "@chakra-ui/react";

import Main from "./Main";
import PasswordView from "./PasswordsView";
import UserPage from "./UserPage";
import PatchNotes from "./PatchNotes";
import GenerationPage from "../pages/GenerationPage";

export default function Switch({ page, loading }: { page: string, loading: boolean }) {

  if (loading) {
    return (
      <Center w={"100%"} h={"100%"}>
        <Spinner color="black" size={"lg"} />
      </Center>
    )
  }

  switch (page) {
    case "folder":
      return <PasswordView />;
      break;
    case "user":
      return <UserPage />;
      break;
    case "patch":
      return <PatchNotes />;
      break;
    case "generation":
      return <GenerationPage />;
      break;
    default:
      return <Main />;
      break;
  }
}
