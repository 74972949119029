import React from "react";
import {
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  Box,
  Spacer,
  Text,
  Spinner,
  Button,
  useDisclosure,
  HStack,
  Tag,
  Center,
} from "@chakra-ui/react";
import {
  BootstrapTable,
  TableHeaderColumn,
  InsertModalHeader,
} from "react-bootstrap-table";
import { EditIcon, DeleteIcon } from "@chakra-ui/icons";
import { useDispatch, useSelector } from "react-redux";
import { contrastColor } from 'contrast-color';

import { Company, Folder, Password, Role, User } from "../../models/models";
import DynamicModal from "./modals/DynamicModal";
import { getFromStorage, unCryptData } from "../../utils/utils";
import { removeRoleAdmin, updateRoleAdmin, updateUserAdmin } from "../../store/actions";
import EditModal from "./modals/EditModal";
import { variables } from "../../utils/variables";

export default function RoleTab({
  user,
  users,
  company,
  folders,
  roles,
  passwords,
}: {
  user: User | null;
  users: User[] | null;
  company: Company[] | null;
  folders: Folder[] | null;
  roles: Role[] | null;
  passwords: Password[] | null;
}) {
  const dispatch = useDispatch();

  const displayCompany = (id: string) => {
    if (company) {
      var name = company.find((obj) => obj._id === id);
      if (name) {
        return unCryptData(name.name);
      } else {
        return "";
      }
    } else {
      return "";
    }
  };

  const displayPassword = (password: string) => {
    return new Array(Math.floor(password.length / 8)).join("*");
  };

  const displayFolder = (ids: string[]) => {
    return (
      <HStack spacing={4}>
        {ids?.map((id: string) => {
          var folder = folders?.find((obj) => obj._id === id);
          return (
            <Tag
              size={"lg"}
              key={folder?._id}
              bg={folder?.color ? unCryptData(folder?.color) : "teal"}
              color={folder?.color ? contrastColor({ bgColor: folder?.color}) : "#1F1F1F"}
              fontSize={"13px"}
              padding={"5px"}
            >
              {unCryptData(folder?.name)}
            </Tag>
          );
        })}
      </HStack>
    );
  };

  const displayColor = (color: string) => {
    return (
      <HStack spacing={4}>
        <Tag
          size={"lg"}
          variant="subtle"
          fontSize={"13px"}
          padding={"5px"}
          bg={color ? unCryptData(color) : "#000000"}
        >
        </Tag>
      </HStack>
    );
  }

  const displayString = (string: string) => {
    return <Text>{unCryptData(string)}</Text>;
  };

  const displayAction = (cellContent: any, row: any) => {
    return (
      <Button
        colorScheme="red"
        onClick={async () => {
            var proceed = window.confirm(
              "Are you sure you want to remove this item ? \n" +
                unCryptData(row.name)
            );
            if (proceed) {
              dispatch(removeRoleAdmin(getFromStorage("token"), row._id, row.company));
            }
        }}
      >
        <DeleteIcon />
      </Button>
    );
  };

  function onAfterSaveCell(row: any, cellName: any, cellValue: any) {
    console.log(row)
    
    dispatch(updateRoleAdmin(getFromStorage("token"), row, row.company))

  }

  const createCustomModal = (
    onModalClose: any,
    onSave: any,
    columns: any,
    validateState: any,
    ignoreEditable: any
  ) => {
    columns.forEach((column: any) => {
      if (column.value) {
        delete column.value;
      }
    });
    const attr = {
      onModalClose,
      onSave,
      columns,
      validateState,
      ignoreEditable,
      folders,
      roles,
      company: company,
      object: "Role",
      user: user
    };
    return <DynamicModal {...attr} />;
  };

  const modalEditor = (onUpdate: any, props: any) => (<EditModal onUpdate={ onUpdate } {...props} options={{roles, folders}} crypted={true} object={"role"} />);

  const options = {
    insertModal: createCustomModal,
  };

  if (!user || !roles) {
    return (
      <Center>
        <Spinner mt={"7vh"} size={"lg"} />
      </Center>
    );
  }

  return (
    <div>
      <BootstrapTable
        data={roles}
        insertRow={true}
        //deleteRow={true}
        // search={true}
        cellEdit={{
          mode: "dbclick",
          blurToSave: true,
          afterSaveCell: onAfterSaveCell,
        }}
        //exportCSV={ true }
        options={options}
        striped={variables.striped}
        hover
      >
        <TableHeaderColumn
          isKey
          dataField="_id"
          headerAlign="left"
          dataAlign="left"
          width={"65px"}
          hiddenOnInsert
        >
          ID
        </TableHeaderColumn>
        <TableHeaderColumn
          dataField="name"
          headerAlign="left"
          dataAlign="left"
          dataSort
          dataFormat={displayString}
          customEditor={ { getElement: modalEditor } }
        >
          Name
        </TableHeaderColumn>
        <TableHeaderColumn
          dataField="company"
          dataSort
          hiddenOnInsert
          dataFormat={displayCompany}
          editable={false}
        >
          Company
        </TableHeaderColumn>
        <TableHeaderColumn
          dataField="folder"
          dataSort
          dataFormat={displayFolder}
          tdStyle={{overflowX: "auto"}}
          customEditor={ { getElement: modalEditor } }
        >
          Folder
        </TableHeaderColumn>
        <TableHeaderColumn
          dataField="color"
          dataSort
          dataFormat={displayColor}
          customEditor={ { getElement: modalEditor } }
        >
          Color
        </TableHeaderColumn>
        <TableHeaderColumn
          dataField="action"
          export={false}
          editable={false}
          width={"70px"}
          hiddenOnInsert={true}
          dataFormat={displayAction}
          dataAlign="center"
        >
          Action
        </TableHeaderColumn>
      </BootstrapTable>
    </div>
  );
}
