import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Flex,
  Spacer,
  Center,
  Text,
  Square,
  Box,
  Textarea,
  Button,
  Icon
} from "@chakra-ui/react";
import { CloseIcon } from "@chakra-ui/icons"
import { MdOutlineNotes } from "react-icons/md"

import notesJson from "../assets/notes.json";
import { setPage } from "../store/actions";
import { Folder, Store } from "../models/models";

export default function PatchNotes() {
    const [notes, setNotes] = React.useState<Array<any>>([]);
    
    const folder: Folder | undefined = useSelector((state: Store) => state.folder);

    const dispatch = useDispatch();

    useEffect(() => {
        var list = notesJson.items.sort(function (a: any, b: any) {
            return new Date(b.date).getTime() - new Date(a.date).getTime()
        })
        setNotes(list)
    }, [])

    return (
        <Box w={"100%"} h={"100vh"} overflow={"auto"} className="scrollPerso">
            <Box 
                zIndex={"1"}
                position="absolute" 
                top={"15px"} 
                right={"30px"} 
                style={{cursor: "pointer"}} 
                onClick={() => {
                    if (folder) {
                        dispatch(setPage("folder"));
                    } else {
                        dispatch(setPage("main"));
                    }
                }}>
                <CloseIcon />
            </Box>
            <Box h="25vh" w="100%" p="5% 2%" position={"relative"}>
                <Center fontSize="80px" top={"70px"} left={65} pl={10} className="titleFolder" position={"absolute"} zIndex={100}>
                    Patch notes
                    <Icon as={MdOutlineNotes} ml={"15px"} />
                </Center>
            </Box> 
            <Box w="100%" p="1% 9% 10% 9%">
                {
                    notes.map((item: any) => {
                        return (
                            <Box mt={"40px"}>
                                <Text fontSize={"24px"} mb={"0px"} fontWeight={"bold"}>{item.version} - {item.title}</Text>
                                <Text fontSize={"12px"} mb={"10px"} opacity={"0.6"}>{item.date}</Text>
                                <Text fontSize={"17px"} style={{whiteSpace: "pre-line"}}>{item.description}</Text>
                            </Box>
                        )
                    })
                }
            </Box>
        </Box>
    );
}
