import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Center,
  Spinner,
  Button,
  HStack,
  Tag,
  Input,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Text,
  useToast,
  FormControl,
  FormLabel
} from "@chakra-ui/react";

export default function ModalEdit({ onClose, isOpen, action, title, text, btnText, defaultValue }: any) {
    
    const [value, setValue] = React.useState<string>("");

    useEffect(() => {
        setValue(defaultValue);
    }, [isOpen])

    function submit() {
        action(value);
        onClose();
    }

    return (
        <Modal isOpen={isOpen} onClose={onClose} size={"5xl"}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader fontSize={"18px"}>
                    {title}
                </ModalHeader>
                <ModalCloseButton size={"lg"} m={"10px"} />
                <ModalBody p={"20px 20px"}>
                    <FormControl>
                        <FormLabel fontSize={"15px"}>{text}</FormLabel>
                        <Input 
                            autoFocus
                            placeholder='Value' 
                            size={"lg"} 
                            fontSize={"15px"} 
                            value={value}
                            onChange={(e) => setValue(e.currentTarget.value)}
                            onKeyDown={(e) => e.key === 'Enter' && submit()}
                            />
                    </FormControl>
                </ModalBody>
                <ModalFooter>
                <Button
                    mr={3}
                    onClick={onClose}
                    fontSize={"16px"}
                    size={"lg"}
                    variant="ghost"
                >
                    Close
                </Button>
                <Button
                    colorScheme="blue"
                    fontSize={"16px"}
                    size={"lg"}
                    onClick={() => submit()}
                >
                    {btnText}
                </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}