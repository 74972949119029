import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route, Link, useNavigate } from "react-router-dom";
import { List } from "@mui/material";
import {
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Box,
  Center,
  Spacer,
  Text,
  Flex,
  Button,
  Spinner,
  useColorModeValue,
} from "@chakra-ui/react";
import { ArrowBackIcon, ArrowForwardIcon } from "@chakra-ui/icons";

import ContactForm from "../components/ContactForm";
import { Colors } from "../style/color";

export default function ContactPage() {
    
    const navigate = useNavigate();
    
    const color = useColorModeValue(Colors.textBlue, Colors.textGray);

    return (
        <Box w={"100%"} h={"100vh"}>
            <ArrowBackIcon
                w={10}
                h={10}
                position={"absolute"}
                color={color}
                top={"30px"}
                left={"30px"}
                onClick={() => navigate(-1)}
                style={{ cursor: "pointer", zIndex: 1 }}
                />
            <ContactForm />
        </Box>
    );
}
