import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Center,
  Spinner,
  Button,
  HStack,
  Tag,
  useDisclosure,
  Modal,
  Icon
} from "@chakra-ui/react";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { EditIcon, DeleteIcon } from "@chakra-ui/icons";
import { MdPersonAdd } from "react-icons/md";
import {
  BootstrapTable,
  TableHeaderColumn,
  InsertModalHeader,
} from "react-bootstrap-table";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import { contrastColor } from 'contrast-color';
import "../../../node_modules/react-bootstrap-table/dist/react-bootstrap-table-all.min.css";
import "../../../node_modules/react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";

import { Access, Company, Folder, Role, Store, User } from "../../models/models";
import { getFromStorage, unCryptData } from "../../utils/utils";
import { removeUserAdmin, updateUserAdmin } from "../../store/actions";
import DynamicModal from "./modals/DynamicModal";
import ModalContentUser from "./modals/ModalAddUser";
import EditModal from "./modals/EditModal";

import "../../style/styles.css"
import { variables } from "../../utils/variables";
import { getFolderAPI } from "../../utils/API";

export default function UserTab({
  user,
  users,
  company,
  folders,
  roles,
}: {
  user: User | null;
  users: User[] | null;
  company: Company[] | null;
  folders: Folder[] | null;
  roles: Role[] | null;
}) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const dispatch = useDispatch();

  const displayCompany = (id: string) => {
    if (company) {
      var name = company.find((obj) => obj._id === id);
      if (name) {
        return unCryptData(name.name);
      } else {
        return "";
      }
    } else {
      return "";
    }
  };

  const displayPassword = (password: string) => {
    return new Array(Math.floor(password.length / 8)).join("*");
  };

  const displayFolder = (ids: string[]) => {
    return (
      <HStack spacing={4} w="fit-content">
        {ids?.map((id: string) => {
          var folder = folders?.find((obj) => obj._id === id);
          if (folder) {
            return (
              <Tag
                key={folder?._id}
                bg={folder?.color ? unCryptData(folder?.color) : "teal"}
                color={folder?.color ? contrastColor({ bgColor: folder?.color}) : "#000000"}
                size={"lg"}
                fontSize={"13px"}
                padding={"5px"}
              >
                {unCryptData(folder?.name)}
              </Tag>
            );
          }
        })}
      </HStack>
    );
  };

  const displayRole = (ids: string[]) => {
    return (
      <HStack spacing={4}>
        {ids?.map((id: string, index: number) => {
          var role = roles?.find((obj) => obj._id === id);
          return (
            <Tag
              size={"lg"}
              key={role?._id}
              bg={role?.color ? unCryptData(role?.color) : "teal"}
              color={role?.color ? contrastColor({ bgColor: role?.color}) : "#000000"}
              fontSize={"13px"}
              padding={"5px"}
            >
              {unCryptData(role?.name)}
            </Tag>
          );
        })}
      </HStack>
    );
  };

  const displayAction = (cellContent: any, row: any) => {
    return (
      <Button
        colorScheme="red"
        onClick={async () => {
          var proceed = window.confirm(
            "Are you sure you want to remove this item ? \n" + row.name
          );
          if (proceed) {
            var user2Update = {...row};
            user2Update.company = null;
            user2Update.access = Access.NONE;
            user2Update.role = [];
            user2Update.folder = [];
            dispatch(updateUserAdmin(getFromStorage("token"), user2Update, row.company));
          }
        }}
      >
        <DeleteIcon />
      </Button>
    );
  };

  const displayAccess = (cellContent: any, row: any) => {
    return (
      <HStack spacing={4}>
        <Tag
          size={"lg"}
          key={row._id}
          variant="subtle"
          colorScheme="green"
          fontSize={"13px"}
          padding={"5px"}
        >
          {row.access}
        </Tag>
      </HStack>
    );
  };

  async function onAfterSaveCell(row: any, cellName: any, cellValue: any) {
    console.log(user?._id, cellName, cellValue);
    var response = await getFolderAPI(getFromStorage("token"), null, null, row._id)
    console.log(response.data)

    response.data.folder.forEach((o: Folder) => {
      row.folder = row.folder.some((e: any) => JSON.stringify(e) === JSON.stringify(o._id)) ? row.folder : [...row.folder, o._id];
    })

    console.log(row)

    dispatch(updateUserAdmin(getFromStorage("token"), row, row.company))
  }

  const createCustomModal = (
    onModalClose: any,
    onSave: any,
    columns: any,
    validateState: any,
    ignoreEditable: any
  ) => {
    columns.forEach((column: any) => {
      if (column.value) {
        delete column.value;
      }
    });
    const attr = {
      onModalClose,
      onSave,
      isOpen,
      columns,
      validateState,
      ignoreEditable,
      folders,
      roles,
      company: company,
      object: "User",
      user: user
    };
    return <DynamicModal {...attr} />;
  };

  const createCustomButtonGroup = (props: any) => {
    return (
      <ButtonGroup className="my-custom-class">
        {props.insertBtn}
        <button type="button" className={`btn btn-primary`} onClick={onOpen}>
          <Center>
            <Icon as={MdPersonAdd} mr={2} />
            Add User
          </Center>
        </button>
      </ButtonGroup>
    );
  };

  const modalEditor = (onUpdate: any, props: any) => (<EditModal onUpdate={ onUpdate } {...props} options={{roles, folders}} crypted={false} object={"user"} />);

  const options = {
    insertModal: createCustomModal,
    btnGroup: createCustomButtonGroup,
  };

  if (!user || !users) {
    return (
      <Center>
        <Spinner mt={"7vh"} size={"lg"} />
      </Center>
    );
  }

  return (
    <div>
      <BootstrapTable
        data={users}
        insertRow={true}
        //deleteRow={true}
        // search={true}
        cellEdit={{
          mode: "dbclick",
          blurToSave: true,
          afterSaveCell: onAfterSaveCell,
        }}
        //exportCSV={ true }
        options={options}
        striped={variables.striped}
        hover
      >
        <TableHeaderColumn
          isKey
          dataField="_id"
          headerAlign="left"
          dataAlign="left"
          width={"65px"}
          hiddenOnInsert
        >
          ID
        </TableHeaderColumn>
        <TableHeaderColumn
          dataField="name"
          headerAlign="left"
          dataAlign="left"
          customEditor={ { getElement: modalEditor } }
          dataSort
        >
          Name
        </TableHeaderColumn>
        <TableHeaderColumn dataField="password" dataFormat={displayPassword} editable={false}>
          Password
        </TableHeaderColumn>
        <TableHeaderColumn
          dataField="company"
          dataSort
          hiddenOnInsert
          dataFormat={displayCompany}
          editable={false}
        >
          Company
        </TableHeaderColumn>
        <TableHeaderColumn
          dataField="folder"
          dataSort
          dataFormat={displayFolder}
          tdStyle={{overflowX: "auto"}}
          customEditor={ { getElement: modalEditor } }
        >
          Folder
        </TableHeaderColumn>
        <TableHeaderColumn
          dataField="role"
          dataSort
          dataFormat={displayRole}
          tdStyle={{overflowX: "auto"}}
          customEditor={ { getElement: modalEditor } }
        >
          Role
        </TableHeaderColumn>
        <TableHeaderColumn 
          dataField="access" 
          dataFormat={displayAccess}
          customEditor={ { getElement: modalEditor } }
        >
          Access
        </TableHeaderColumn>
        <TableHeaderColumn
          dataField="action"
          export={false}
          editable={false}
          width={"70px"}
          hiddenOnInsert={true}
          dataFormat={displayAction}
          dataAlign="center"
        >
          Action
        </TableHeaderColumn>
      </BootstrapTable>

      <ModalContentUser onClose={onClose} isOpen={isOpen} company={company} />
    </div>
  );
}
