import React, { useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import {
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Box,
  Spacer,
  Text,
  Flex,
} from "@chakra-ui/react";
import { ArrowBackIcon } from "@chakra-ui/icons";
import { useDispatch, useSelector } from "react-redux";
import { Navbar, Container } from "react-bootstrap";

import { Access, Company, Folder, Password, Role, Store, User } from "../models/models";
import UserTab from "../components/admin/UserTab";
import PasswordTab from "../components/admin/PasswordTab";
import FolderTab from "../components/admin/FolderTab";
import RoleTab from "../components/admin/RoleTab";
import CompanyTab from "../components/admin/CompanyTab";
import {
  checkAdmin,
  getCompanyAPI,
  getFolderAPI,
  getPasswordAPI,
  getRoleAPI,
  getUserAPI,
} from "../utils/API";
import {
  getCompaniesAdmin,
  getFoldersAdmin,
  getPasswordsAdmin,
  getRolesAdmin,
  getUsersAdmin,
} from "../store/actions";
import { clearStorage, getFromStorage } from "../utils/utils";
import { Link } from "react-router-dom";

export default function Admin() {
  const [user, setUser] = React.useState<User | null>(null);

  const users: User[] = useSelector((state: Store) => state.usersAdmin);
  const folders: Folder[] = useSelector((state: Store) => state.foldersAdmin);
  const roles: Role[] = useSelector((state: Store) => state.rolesAdmin);
  const company: Company[] = useSelector(
    (state: Store) => state.companiesAdmin
  );
  const passwords: Password[] = useSelector(
    (state: Store) => state.passwordsAdmin
  );

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    setUser(JSON.parse(getFromStorage("user")));
    setData(JSON.parse(getFromStorage("user")));
  }, []);

  const setData = async (user: User) => {
    var token = getFromStorage("token");
    var response = await checkAdmin(token).catch((e) => {return e.response})
    if (response.data.admin === true) {
      dispatch(getUsersAdmin(token, user.company));
      dispatch(getCompaniesAdmin(token, user.company));
      dispatch(getFoldersAdmin(token, user.company));
      dispatch(getRolesAdmin(token, user.company));
      dispatch(getPasswordsAdmin(token, user.company));
    } else {
      clearStorage()
      navigate("/login?message=Expired+session", {replace: true})
    }
  };

  return (
    <Box fontSize={"16px"}>
      <Flex padding={"10px 30px"} bg="#1C2942">
        <Link to={"/app"} style={{margin: "auto 30px auto 0px", color: 'inherit', textDecoration: 'inherit'}}>
          <ArrowBackIcon
            w={8}
            h={8}
            color="white"
            style={{ cursor: "pointer" }}
          />
        </Link>
        <Box mr={"20px"}>
          <img
            alt=""
            src="https://logos.textgiraffe.com/logos/logo-name/Admin-designstyle-pastel-m.png"
            width="50"
            height="50"
            className="d-inline-block align-top"
          />
        </Box>
        <Box m={"auto auto auto 10px"} color="white">
          Admin panel
        </Box>
      </Flex>
      <Box w="90%" h="95%" m="auto" mt="3%">
        <Tabs size="lg">
          <TabList>
            <Tab fontSize={"18px"}>User</Tab>
            <Tab fontSize={"18px"}>Password</Tab>
            <Tab fontSize={"18px"}>Folder</Tab>
            <Tab fontSize={"18px"}>Role</Tab>
            <Tab fontSize={"18px"}>Company</Tab>
          </TabList>

          <TabPanels>
            <TabPanel>
              <UserTab
                user={user}
                users={users}
                company={company}
                folders={folders}
                roles={roles}
              />
            </TabPanel>
            <TabPanel>
              <PasswordTab
                user={user}
                users={users}
                company={company}
                folders={folders}
                roles={roles}
                passwords={passwords}
              />
            </TabPanel>
            <TabPanel>
              <FolderTab
                user={user}
                users={users}
                company={company}
                folders={folders}
                roles={roles}
                passwords={passwords}
              />
            </TabPanel>
            <TabPanel>
              <RoleTab
                user={user}
                users={users}
                company={company}
                folders={folders}
                roles={roles}
                passwords={passwords}
              />
            </TabPanel>
            <TabPanel>
              <CompanyTab
                user={user}
                users={users}
                company={company}
                folders={folders}
                roles={roles}
                passwords={passwords}
              />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>
    </Box>
  );
}
