import React, { useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import HomeIcon from "@mui/icons-material/Home";
import EventIcon from "@mui/icons-material/Event";
import DashboardIcon from "@mui/icons-material/Dashboard";
import { ArrowForwardIcon, EditIcon } from "@chakra-ui/icons";
import {
  Flex,
  Spacer,
  Center,
  Text,
  Tooltip,
  Box,
  Button,
  Icon,
  Checkbox,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  SliderMark,
  useColorModeValue,
} from "@chakra-ui/react";
import { Colors } from "../style/color";

export default function GenerationPanel({direction, textBtn, action}: {direction: string, textBtn?: string, action?: any}) {
    const [sliderValue, setSliderValue] = React.useState<number>(5)
    const [showTooltip, setShowTooltip] = React.useState<boolean>(false)
    const [password, setPassword] = React.useState<string>("")
    const [checkedItems, setCheckedItems] = React.useState<boolean[]>([true, true, true, true])

    useEffect(() => {
        generatePassword()
    }, [])

    function generatePassword(length=sliderValue, check0=checkedItems[0], check1=checkedItems[1], check2=checkedItems[2], check3=checkedItems[3]) {
        var chars = "";
        if (check0) {
            chars += "ABCDEFGHIJKLMNOPQRSTUVWXYZ"
        }
        if (check1) {
            chars += "abcdefghijklmnopqrstuvwxyz"
        }
        if (check2) {
            chars += "0123456789"
        }
        if (check3) {
            chars += "!@#$%^&*"
        }

        var passwordTemp = ""
        
        for (var i = 0; i <= length - 1; i++) {
            var randomNumber = Math.floor(Math.random() * chars.length);
            passwordTemp += chars.substring(randomNumber, randomNumber +1);
        }

        setPassword(passwordTemp);
    }

    return (
        <Box w={"100%"} h={"100%"}>
            <Box style={{display: "flex", flexDirection: direction === "row" ? "row" : "column"}}>
                <Box flex={1} mr={direction === "row" ? "10px" : "0px"}>
                    <Text>Length: {sliderValue}</Text>
                    <Slider
                        id='slider'
                        defaultValue={7}
                        min={0}
                        max={40}
                        size={"lg"}
                        colorScheme='teal'
                        onChange={(v) => {
                            setSliderValue(v)
                            generatePassword(v, undefined, undefined, undefined, undefined)
                        }}
                        onMouseEnter={() => setShowTooltip(true)}
                        onMouseLeave={() => setShowTooltip(false)}
                        >
                        <SliderMark value={10} mt='1' ml='-2.5' fontSize='lg'>
                            10
                        </SliderMark>
                        <SliderMark value={20} mt='1' ml='-2.5' fontSize='lg'>
                            20
                        </SliderMark>
                        <SliderMark value={30} mt='1' ml='-2.5' fontSize='lg'>
                            30
                        </SliderMark>
                        <SliderTrack>
                            <SliderFilledTrack />
                        </SliderTrack>
                        <Tooltip
                            hasArrow
                            bg='teal.500'
                            color='white'
                            placement='top'
                            isOpen={showTooltip}
                            label={`${sliderValue} `}
                        >
                            <SliderThumb />
                        </Tooltip>
                    </Slider>
                </Box>
                <Box flex={1} ml={direction === "row" ? "10px" : "0px"} mt={direction === "row" ? "0px" : "20px"}>
                    <Text>Special characters</Text>
                    <Flex>
                        <Box flex={1}>
                            <Checkbox 
                                size={"lg"} 
                                isChecked={checkedItems[0]}
                                onChange={(e) => {
                                    setCheckedItems([e.target.checked, checkedItems[1], checkedItems[2], checkedItems[3]])
                                    generatePassword(undefined, e.target.checked, undefined, undefined, undefined)
                                }}
                                >
                                A-Z
                            </Checkbox>
                        </Box>
                        <Box flex={1}>
                            <Checkbox 
                                size={"lg"} 
                                isChecked={checkedItems[1]}
                                onChange={(e) => {
                                    setCheckedItems([checkedItems[0], e.target.checked, checkedItems[2], checkedItems[3]])
                                    generatePassword(undefined, undefined, e.target.checked, undefined, undefined)
                                }}
                                >
                                a-z
                            </Checkbox>
                        </Box>
                        <Box flex={1}>
                            <Checkbox 
                                size={"lg"} 
                                isChecked={checkedItems[2]}
                                onChange={(e) => {
                                    setCheckedItems([checkedItems[0], checkedItems[1], e.target.checked, checkedItems[3]])
                                    generatePassword(undefined, undefined, undefined, e.target.checked, undefined)
                                }}
                                >
                                0-9
                            </Checkbox>
                        </Box>
                        <Box flex={1}>
                            <Checkbox 
                                size={"lg"} 
                                isChecked={checkedItems[3]}
                                onChange={(e) => {
                                    setCheckedItems([checkedItems[0], checkedItems[1], checkedItems[2], e.target.checked])
                                    generatePassword(undefined, undefined, undefined, undefined, e.target.checked)
                                }}
                                >
                                !@#$%^&*
                            </Checkbox>
                        </Box>
                    </Flex>
                </Box>
            </Box>
            <Box mt={"5%"}>
                <Flex direction={"row"}>
                    <Text fontSize={"18px"} textAlign={"center"}>Password:</Text>
                    <Center ml={"10px"}>
                        <Text fontSize={"15px"} textAlign={"center"}>{password}</Text>
                    </Center>
                </Flex>
            </Box>
            <Flex direction={"row"} alignItems={"flex-end"}>
                <Button
                    m={"40px 0px auto auto"}
                    colorScheme="teal"
                    type="submit"
                    size={"lg"}
                    fontSize={"14px"}
                    onClick={() => {action(password)}}
                    >
                    {textBtn ? textBtn : "Copy"}
                </Button>
            </Flex>
        </Box>
    )
}