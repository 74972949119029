import React, {useEffect} from 'react';
import {
    Center,
    Spinner,
    Button,
    HStack,
    Tag,
    useDisclosure,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Text,
    Input,
    Switch,
    Stack
  } from "@chakra-ui/react";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { CirclePicker } from 'react-color';

import { cryptData, unCryptData } from '../../../utils/utils';
import { Access } from '../../../models/models';
import { select_style } from '../../../style/select';

const animatedComponents = makeAnimated();

export default function NameEditor(column: any) {

    const [value, setValue] = React.useState<string>("");
    const [open, setOpen] = React.useState<boolean>(true);
    const [key, setKey] = React.useState<string>("");
    const [choices, setChoices] = React.useState<any>([]);
    const [options, setOptions] = React.useState<any[]>([]);

    useEffect(() => {
        console.log(column);
        setValue(column.object === "user" ? column.defaultValue : unCryptData(column.defaultValue));
        var keyObject = Object.keys(column.row).find(key => column.row[key] === column.defaultValue);
        console.log(keyObject);
        if (keyObject) {
            setKey(keyObject);
            if ((keyObject === "folder" || keyObject === "role") && !(column.object === "password")) {
                var optionsList: any = []
                column.options[keyObject + "s"].forEach((element: any) => optionsList.push({ label: unCryptData(element.name), value: element._id }))
                setOptions(optionsList);
                var selectOptions: any = []
                column.defaultValue.forEach((id: string) => {
                    optionsList.forEach((element: any) => {
                        if (element.value === id) {
                            selectOptions.push(element)
                        }
                    })
                })
                setChoices(selectOptions)
            }
            if ((keyObject === "folder" || keyObject === "role") && (column.object === "password")) {
                var optionsList: any = []
                column.options[keyObject + "s"].forEach((element: any) => optionsList.push({ label: unCryptData(element.name), value: element._id }))
                setOptions(optionsList);
                var selectOptions: any = []
                optionsList.forEach((element: any) => {
                    if (element.value === column.defaultValue) {
                        selectOptions.push(element)
                    }
                })
                setChoices(selectOptions)
            }
            if (keyObject === "access") {
                var optionsList: any = []
                optionsList.push({ label: Access.ADMIN, value: Access.ADMIN })
                optionsList.push({ label: Access.USER, value: Access.USER })
                optionsList.push({ label: Access.GUEST, value: Access.GUEST })
                setOptions(optionsList);
                var selectOptions: any = []
                optionsList.forEach((element: any) => {
                    if (element.value === column.defaultValue) {
                        selectOptions.push(element)
                    }
                })
                setChoices(selectOptions)
            }
        } else {
            setKey("access")
        }
    }, [])

    function update() {
        var cellValue;
        switch (key) {
            case "folder":
                if (column.object === "password") {
                    cellValue = choices.value
                } else {
                    cellValue = []
                    choices.forEach((element: any) => {
                        cellValue.push(element.value)
                    })
                    console.log(choices)
                }
                break;
            case "role":
                if (column.object === "password") {
                    cellValue = choices.value
                } else {
                    cellValue = []
                    choices.forEach((element: any) => {
                        cellValue.push(element.value)
                    })
                }
                break;
            
            case "access":
                cellValue = choices.value
                break;
        
            default:
                cellValue = column.object === "user" ? value : cryptData(value);
                break;
        }
        
        column.onUpdate(cellValue)
    }

    function inputObject() {
        switch (key) {
            case "folder":
                return (
                    <Select
                        styles={select_style}
                      closeMenuOnSelect={true}
                      components={animatedComponents}
                      isMulti={column.object !== "password"}
                      options={options}
                      value={choices}
                      onChange={(choice: any) => setChoices(choice)}
                    />
                )
            case "role":
                return (
                    <Select
                        styles={select_style}
                        closeMenuOnSelect={true}
                        components={animatedComponents}
                        isMulti={column.object !== "password"}
                        options={options}
                        value={choices}
                        onChange={(choice: any) => setChoices(choice)}
                    />
                )
            case "access":
                return (
                    <Select
                        styles={select_style}
                        closeMenuOnSelect={true}
                        components={animatedComponents}
                        options={options}
                        value={choices}
                        onChange={(choice: any) => setChoices(choice)}
                    />
                )
            case "color":
                return (
                    <Center>
                        <CirclePicker 
                            colors={["#f44336", "#e91e63", "#9c27b0", "#673ab7", "#3f51b5", "#2196f3", "#03a9f4", "#00bcd4", "#009688", "#4caf50", "#8bc34a", "#cddc39", "#ffeb3b", "#ffc107", "#ff9800", "#ff5722", "#795548", "#607d8b"]} 
                            color={value} 
                            onChangeComplete={(color: any) => setValue(color.hex)} 
                            />
                    </Center>
                )
            default:
                return (
                    <Input
                    type="text"
                    size={"lg"}
                    fontSize={"15px"}
                    value={ value }
                    onKeyDown={(e) => e.key === 'Enter' && update()}
                    onChange={ e => { setValue(e.currentTarget.value) }}
                />
                )
        }
    }

    return (
        <Modal isOpen={open} onClose={() => setOpen(false)} size={"5xl"}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader fontSize={"18px"}>Update {key} of {unCryptData(column.row.name)}</ModalHeader>
                <ModalCloseButton size={"lg"} m={"10px"} />
                <ModalBody m={"10px 5px 20px 5px"}>
                    {inputObject()}
                </ModalBody>
                <ModalFooter>
                <Button
                    mr={3}
                    onClick={() => setOpen(false)}
                    fontSize={"16px"}
                    size={"lg"}
                    variant="ghost"
                >
                    Close
                </Button>
                <Button
                    colorScheme="blue"
                    fontSize={"16px"}
                    size={"lg"}
                    onClick={() => update()}
                >
                    Update
                </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );

}