import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import { List } from "@mui/material";
import {
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Box,
  Center,
  Spacer,
  Text,
  Flex,
  Button,
  Spinner,
} from "@chakra-ui/react";
import { ArrowBackIcon, ArrowForwardIcon } from "@chakra-ui/icons";

import { User } from "../models/models";
import { verifyAccount } from "../utils/API";
import { toast } from "react-toastify";

export default function VerifyAccountPage() {

    const [user, setUser] = React.useState<User | undefined>(undefined);
    const [loading, setLoading] = React.useState<boolean>(true);

    useEffect(() => {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);

        const userId = urlParams.get('userid');

        if (userId) {
            sendValidation(userId)
        }
        
    }, [])

    async function sendValidation(userId: string) {
        
        var response = await verifyAccount(userId).catch((e) => {return e.response})

        if (response.status === 200) {
            setLoading(false)
        } else {
            toast.error("Server error, contact your team", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
                });
        }

    }

    if (loading) {
        return (
            <Box w={"100%"} h={"100vh"}>
                <Box h="25vh" w="100%" p="10% 10%" position={"relative"}>
                    <Text fontSize={"40px"}>Loading<Spinner ml={"20px"}/></Text>
                </Box>
            </Box>
        )
    }

    return (
        <Box w={"100%"} h={"100vh"}>
            <Box h="25vh" w="100%" p="10% 10%" position={"relative"}>
                <Text fontSize="80px" className="titleFolder">
                    Your account has been certified
                </Text>
                <Text>You can access to your Passap account now</Text>
                <Flex mt={"5vh"}>
                    <Link to={"/login"} style={{ textDecoration: "none" }}>
                        <Button
                            m={"auto"}
                            colorScheme="teal"
                            type="submit"
                            size={"lg"}
                            fontSize={"14px"}
                            >
                            Login
                        </Button>
                    </Link>
                    <Link to={"/"} style={{ textDecoration: "none", marginLeft: "10px" }}>
                        <Button
                            m={"auto"}
                            colorScheme="teal"
                            type="submit"
                            size={"lg"}
                            fontSize={"14px"}
                            >
                            Home
                        </Button>
                    </Link>
                </Flex>
            </Box>
        </Box>
    );
}
