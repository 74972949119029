export interface User {
  _id?: string;
  name: string;
  password: string;
  email: string;
  company: string;
  folder: string[];
  role: string[];
  access: string;
  created_at?: string;
  account_validate?: boolean;
}

export interface Company {
  _id?: string;
  name: string;
  created_at?: string;
  created_by?: string;
}

export interface Role {
  _id?: string;
  name: string;
  company: string;
  folder: string[];
  color: string;
  created_at?: string;
  created_by?: string;
}

export interface Folder {
  _id?: string;
  name: string;
  company?: string;
  user?: string;
  color: string;
  created_at?: string;
  created_by?: string;
}

export interface Password {
  _id?: string;
  name: string;
  username: string;
  password: string;
  folder: string;
  url: string;
  created_at?: string;
  created_by?: string;
}

export interface Store {
  user: User | undefined;
  folders: Folder[];
  foldersPerso: Folder[];
  folder: Folder | undefined;
  roles: Role[];
  passwords: Password[];
  company: Company | undefined;
  page: string;
  usersAdmin: User[];
  foldersAdmin: Folder[];
  rolesAdmin: Role[];
  passwordsAdmin: Password[];
  companiesAdmin: Company[];
}

export enum Access {
  OWNER = "owner",
  ADMIN = "admin",
  USER = "user",
  GUEST = "guest",
  NONE = "none"
}
